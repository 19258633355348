<ng-container *ngIf="practice">
  <ng-container *ngIf="createInProgress">
    <p-progressSpinner [style]="{width: '40px', height: '40px', margin: '40px auto', display: 'block'}"
                       styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
  </ng-container>
  <ng-container *ngIf="!createInProgress">
    <ng-container *ngIf="step === 0">
      <div class="subtitle">Search for client in clinic system</div>
      <client-selector [noModal]="true" (clientSelected)="selectClient($event)"></client-selector>
      <ng-container>
        <div class="subtitle">Or enter any phone number</div>
        <div class="phone-input">
          <input pInputText (paste)="validatePhoneInput()" (keydown)="validateKeyPress($event)"
            (keyup)="$event.key == 'Enter' ? usePhoneNumber() : validatePhoneInput()" maxlength="30" type="text"
            [(ngModel)]="phoneNumber" placeholder="Phone number">
          <button pButton label="Next" [disabled]="!phoneNumberIsValid" (click)="usePhoneNumber()"></button>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="step === 1">
      <div class="subtitle">Select a contact</div>
      <ng-container *ngIf="selectedClient">
        <div class="contact-selector-wrap">
          <div class="client-details">
            <div class="name">{{ selectedClient.title }} {{ selectedClient.firstName }} {{ selectedClient.lastName }} ({{ selectedClient.pmsId }})</div>
            <div class="address">
              {{ selectedClient.address1 ? selectedClient.address1 + ',' : '' }}
              {{ selectedClient.address2 ? selectedClient.address2 + ',' : '' }}
              {{ selectedClient.address3 ? selectedClient.address3 + ',' : '' }}
              {{ selectedClient.postcode ? selectedClient.postcode + ',' : '' }}
              {{ selectedClient.state }}
            </div>
          </div>
          <ng-container *ngFor="let contact of this.selectedClient.mobileContacts">
            <div class="contact-selector">
              <div class="label">{{ contact.name }}: {{ contact.value }}</div>
              <button pButton label="Select" class="p-button-sm p-button-ghost" (click)="selectContact(contact)"></button>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <div class="next-step">
        <div class="back" (click)="goBackTo(0)"><img src="assets/images/icons/back.svg" alt=""></div>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 2">
      <div class="subtitle">Select a channel to use when sending updates to the client</div>

      <div class="channel-dropdown-wrap">
        <p-dropdown [options]="channels" [(ngModel)]="currentChannelOption" optionLabel="name" optionDisabled="inactive" class="channel-dropdown">
          <ng-template pTemplate="selectedItem">
            <div class="channel-item channel-item-selected {{ currentChannelOption.className }}" *ngIf="currentChannelOption">
              <img [src]="'assets/images/icons/' + currentChannelOption.icon" class="channel-icon" />
              <div>{{currentChannelOption.name}}</div>
            </div>
          </ng-template>
          <ng-template let-channel pTemplate="item">
            <div class="channel-item {{ channel.className }}">
              <img [src]="'assets/images/icons/' + channel.icon" class="channel-icon" />
              <div>{{channel.name}}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="next-step">
        <div class="back" (click)="goBackTo(1)"><img src="assets/images/icons/back.svg" alt=""></div>
        <p-button (click)="goToFormStep()" label="Next" styleClass="p-button-primary"></p-button>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 3">
      <div class="subtitle">Select a form to send</div>
      <div class="form-selector">
        <form-selector (formSelected)="handleFormSelected($event)" [isRegistered]="isRegistered"></form-selector>
      </div>

      <div class="next-step">
        <div class="back" (click)="goBackTo(2)"><img src="assets/images/icons/back.svg" alt=""></div>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 4">
      <ng-container *ngIf="formLoading">
        <p-progressSpinner [style]="{width: '40px', height: '40px', margin: '40px auto', display: 'block'}"
                           styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
      </ng-container>
      <ng-container *ngIf="!formLoading">
        <div class="subtitle">Select a patient</div>
        <div class="patient-select">
          <ng-container *ngIf="patients.length > 0 || !patientsLoading">
            <patient-selector [disabled]="false" [loading]="patientsLoading" [patients]="patients" (patientSelected)="selectPatient($event)" (morePatients)="handleMorePatients()"></patient-selector>
          </ng-container>
          <ng-container *ngIf="patientsLoading">
            <div class="loading">
              <p-progressSpinner [style]="{width: '40px', height: '40px', margin: '40px auto', display: 'block'}"
                                 styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
            </div>
          </ng-container>
        </div>
        <div class="next-step">
          <div class="back" (click)="goBackTo(3)"><img src="assets/images/icons/back.svg" alt=""></div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="step === 5">
      <ng-container *ngIf="formLoading">
        <p-progressSpinner [style]="{width: '40px', height: '40px', margin: '40px auto', display: 'block'}"
                           styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
      </ng-container>
      <ng-container *ngIf="!formLoading">
        <div class="subtitle">Preview & update pre-filled fields</div>
        <ng-container *ngIf="fieldsToPrefill.length === 0">
          <div class="no-prefill">
            This form contains no pre-filled fields.
          </div>
        </ng-container>
        <ng-container *ngIf="fieldsToPrefill.length > 0">
          <div class="prefills">
            <ng-container *ngFor="let field of fieldsToPrefill">
              <div class="prefill-field">
                <div class="label">{{ field.label }}</div>
                <div class="input">
                  <ng-container *ngIf="selectedClient">
                    <input #prefillInputs pInputText type="text" [name]="field.id" [value]="field.prefillWith | getFieldPrefillValue : selectedClient : selectedPatient">
                  </ng-container>
                  <ng-container *ngIf="!selectedClient">
                    <input #prefillInputs pInputText type="text" [name]="field.id" [value]="">
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <div class="next-step">
          <div class="back" (click)="goBackTo(4)"><img src="assets/images/icons/back.svg" alt=""></div>
          <ng-container *ngIf="sites.length > 1">
            <p-button (click)="goToSiteStep()" label="Next" styleClass="p-button-primary"></p-button>
          </ng-container>
          <ng-container *ngIf="sites.length <= 1">
            <p-button (click)="sendForm()" label="Send Form" styleClass="p-button-primary"></p-button>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="step === 6">
      <div class="subtitle">Select a site</div>
      <div class="site-select input-holder full-width">
        <select class="primary" [(ngModel)]="selectedSiteId">
          <option value="0">Please select...</option>
          <ng-container *ngFor="let site of sites">
            <option value="{{ site.id }}">{{ site.name }}</option>
          </ng-container>
        </select>
        <ng-container *ngIf="siteError">
          <div class="error">{{ siteError }}</div>
        </ng-container>
      </div>
      <div class="next-step">
        <div class="back" (click)="goBackTo(5)"><img src="assets/images/icons/back.svg" alt=""></div>
        <p-button (click)="sendForm()" label="Send Form" styleClass="p-button-primary"></p-button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
