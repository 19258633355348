<ng-container *ngIf="practice">

  <ng-container *ngIf="step === 0">
    <div class="subtitle">Search for client in clinic system</div>
    <client-selector [noModal]="true" (clientSelected)="selectClient($event)"></client-selector>
  </ng-container>

  <ng-container *ngIf="step === 1">
    <div class="subtitle">Select a contact</div>
    <ng-container *ngIf="selectedClient">
      <div class="contact-selector-wrap">
        <div class="client-details">
          <div class="name">{{ selectedClient.title }} {{ selectedClient.firstName }} {{ selectedClient.lastName }} ({{ selectedClient.pmsId }})</div>
          <div class="address">
            {{ selectedClient.address1 ? selectedClient.address1 + ',' : '' }}
            {{ selectedClient.address2 ? selectedClient.address2 + ',' : '' }}
            {{ selectedClient.address3 ? selectedClient.address3 + ',' : '' }}
            {{ selectedClient.postcode ? selectedClient.postcode + ',' : '' }}
            {{ selectedClient.state }}
          </div>
        </div>
        <ng-container *ngFor="let contact of this.selectedClient.mobileContacts">
          <div class="contact-selector">
            <div class="label">{{ contact.name }}: {{ contact.value }}</div>
            <button pButton label="Select" class="p-button-sm p-button-ghost" (click)="selectContact(contact)"></button>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <div class="next-step">
      <div class="back" (click)="goBackTo(0)"><img src="assets/images/icons/back.svg" alt=""></div>
    </div>
  </ng-container>

  <ng-container *ngIf="step === 2">
    <div class="subtitle">Select a channel to use when sending updates to the client</div>

    <div class="channel-dropdown-wrap">
      <p-dropdown [options]="channels" [(ngModel)]="currentChannelOption" optionLabel="name" optionDisabled="inactive" class="channel-dropdown">
        <ng-template pTemplate="selectedItem">
          <div class="channel-item channel-item-selected {{ currentChannelOption.className }}" *ngIf="currentChannelOption">
            <img [src]="'assets/images/icons/' + currentChannelOption.icon" class="channel-icon" />
            <div>{{currentChannelOption.name}}</div>
          </div>
        </ng-template>
        <ng-template let-channel pTemplate="item">
          <div class="channel-item {{ channel.className }}">
            <img [src]="'assets/images/icons/' + channel.icon" class="channel-icon" />
            <div>{{channel.name}}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>

    <div class="next-step">
      <div class="back" (click)="goBackTo(1)"><img src="assets/images/icons/back.svg" alt=""></div>
      <p-button (click)="goToPatientStep()" label="Next" styleClass="p-button-primary"></p-button>
    </div>
  </ng-container>

  <ng-container *ngIf="step === 3">
    <div class="subtitle">Select a patient</div>
    <div class="patient-select">
      <ng-container *ngIf="patients.length > 0 || !patientsLoading">
        <patient-selector [disabled]="false" [loading]="patientsLoading" [patients]="patients" (patientSelected)="selectPatient($event)" (morePatients)="handleMorePatients()"></patient-selector>
      </ng-container>
      <ng-container *ngIf="patientsLoading">
        <div class="patients-loading">
          <p-progressSpinner [style]="{width: '24px', height: '24px'}" styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
        </div>
      </ng-container>
    </div>
    <div class="next-step">
      <div class="back" (click)="goBackTo(2)"><img src="assets/images/icons/back.svg" alt=""></div>
    </div>
  </ng-container>


  <ng-container *ngIf="step === 4 && selectedClient">
    <form [formGroup]="productForm" class="product-request-form">
      <div class="input-row assignee">
        <div class="label">Assign a colleague? (Optional)</div>
        <user-changer [selected]="selectedAssignee" (userChange)="handleAssigneeChange($event)"></user-changer>
      </div>

      <div class="items" formArrayName="items">
        <ng-container *ngFor="let item of items()?.controls; let i=index" [formGroupName]="i">
          <div class="item">
            <div class="item-info">
              <textarea pInputTextarea formControlName="name" placeholder="Enter product name" class="item-name" maxlength="200"></textarea>
              <p-inputNumber
                class="item-count"
                formControlName="count"
                [showButtons]="true"
                [min]="1"
                buttonLayout="vertical"
                spinnerMode="vertical"
                inputId="vertical"
                decrementButtonClass="p-button-secondary"
                incrementButtonClass="p-button-secondary"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus">
              </p-inputNumber>
            </div>
            <ng-container *ngIf="item.get('name')?.invalid && (item.get('name')?.dirty || item.get('name')?.touched)">
              <div class="error">You must enter a product name</div>
            </ng-container>
            <ng-container *ngIf="item.get('count')?.invalid && (item.get('count')?.dirty || item.get('count')?.touched)">
              <ng-container *ngIf="item.get('count')?.errors?.['required']">
                <div class="error">You must enter a requested amount</div>
              </ng-container>
              <ng-container *ngIf="item.get('count')?.errors?.['max']">
                <div class="error">The requested amount must be less than 1,000</div>
              </ng-container>
            </ng-container>
            <div class="input-row item-auth-row">
              <div class="item-auth">
                <div class="label">Does this product require authorisation?</div>
                <p-selectButton [options]="approvalRequiredOptions" formControlName="approvalRequired" optionLabel="label" optionValue="value"></p-selectButton>
              </div>
              <ng-container *ngIf="items().length > 1">
                <div class="remove" (click)="removeItem(i)">Remove</div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="add-item" (click)="addItem()"><i class="pi pi-plus"></i> Add item</div>
    </form>

    <div class="next-step">
      <div class="back" (click)="goBackTo(3)"><img src="assets/images/icons/back.svg" alt=""></div>
      <p-button (click)="submit()" label="Create request" styleClass="p-button-primary"></p-button>
    </div>
  </ng-container>

  <ng-container *ngIf="step === 5">
    <div class="subtitle">Phone number is invalid</div>
    <p class="descr">Please go back and select a valid number</p>

    <div class="next-step">
      <div>
        <div class="back" (click)="goBackTo(0)"><img src="assets/images/icons/back.svg" alt=""></div>
      </div>
    </div>
  </ng-container>
</ng-container>
