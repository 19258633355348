export const environment = {
  appVersion: require("../../package.json").version,
  name: "New Feature 3",
  production: false,
  secureCookie: true,
  appUrl: "https://messaging-feature-3.vethelpdirect.com",
  backendUrl: "https://api.messaging-feature-3.vethelpdirect.com/api",
  authUrl: "https://auth-new-feature-3.vethelpdirect.com",
  helpUrl: "https://help-new-feature-3.vethelpdirect.com",
  billingUrl: "https://billing-new-feature-3.vethelpdirect.com",
  authClientId: "93dc13fd-4497-4f3b-8e34-f7cc702580af",
  websocketUrl: "https://api.messaging-feature-3.vethelpdirect.com",
  formsApiUrl: "https://feature-3.forms.api.digitalpractice.vet",
  maxImageSize: 800,
  appLogo: "/assets/images/dp-thumb.svg",
  appWideLogo: "/assets/images/dp-logo.svg",
  login: {
    services: ["vhd", "rx-works"],
    logo: "/assets/images/dp-logo.svg",
    logoSize: "large",
  },
  gtag_code: "G-HKMBCFPMNK",
  oneSignal: {
    appId: "e89ab9e7-e2c6-4d66-ab10-110b7d798d78",
    safariWebId: " ",
  },
  jwtPrefix: "FEATURE_3_MESSAGING_",
  noPracticesMessage:
    "You do not have access to any practices. Please contact your system administrator.",
  campaignLimit: 750,
  conversationsPerPage: 20,
  productRequestsPerPage: 20,
  clientsPerPage: 20,
  paymentsPerPage: 20,
  templateMessageLength: 1000,
};
