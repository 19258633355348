import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription, takeWhile } from 'rxjs';
import { ResetShareClientToPms, ResetSharePatientToPms,ClearNewConversationError } from '../../../conversation/state/actions';
import { Practice } from '../../../models/Practice';
import { getCurrentPractice } from '../../../practices/state/selectors';
import { AppState } from '../../../state/reducers';
import { CloseNewConversation } from '../../state/actions';
import { isNewConversationOpen } from '../../state/selectors';
import {
  getCurrentProductRequest,
  getDispenseProductRequestOpen,
  getProductRequestApprovalOpen, getProductRequestDetailContext, getProductRequestDetailOpen,
  getProductRequestForApproval,
  getProductRequestForDispense,
  getProductRequestForPaidConfirmation,
  getProductRequestForPaymentCreate,
  getProductRequestForRejection,
  getProductRequestPaidConfirmationContext,
  getProductRequestPaidConfirmationOpen,
  getProductRequestPaymentCreateOpen,
  getProductRequestRejectionOpen,
  isProductRequestOpen
} from '../../../product-requests/state/selectors';
import {
  CloseDispenseProductRequest,
  CloseProductRequest,
  CloseProductRequestApproval,
  CloseProductRequestDetail,
  CloseProductRequestPaidConfirmation,
  CloseProductRequestPaymentCreate,
  CloseProductRequestRejection
} from '../../../product-requests/state/actions';
import {ProductRequest} from '../../../models/ProductRequest';
import {ProductRequestDetailContext} from '../../../enums/product-request-detail-context';
import {NavigationEnd, Router} from '@angular/router';
import {ProductRequestPaidContext} from '../../../enums/product-request-paid-context.enum';
import { EnvironmentService } from '../../../services/environment.service';
import {getNewClientDepositOpen} from '../../../clients/state/selectors';
import {CloseNewClientDeposit} from '../../../clients/state/actions';
import {isNewFormRequestOpen} from '../../../forms/state/selectors';
import {CloseFormRequest} from '../../../forms/state/actions';


@Component({
  selector: 'system-dialogs',
  templateUrl: './system-dialogs.component.html',
  styleUrls: ['./system-dialogs.component.scss']
})
export class SystemDialogsComponent implements OnInit {
  alive = true;
  showNewConversation = false;
  practice?: Practice;
  practice$?: Observable<Practice | null>;
  productRequestOpen = false;
  approveProductRequestOpen = false;
  productRequestToApprove: ProductRequest | null = null;
  rejectProductRequestOpen = false;
  productRequestToReject: ProductRequest | null = null;
  createProductRequestPaymentOpen = false;
  productRequestToCreatePayment: ProductRequest | null = null;
  productRequestPaidConfirmationOpen = false;
  productRequestPaidConfirmationContext: ProductRequestPaidContext = ProductRequestPaidContext.PAID;
  productRequestForPaidConfirmation: ProductRequest | null = null;
  productRequestDispenseOpen = false;
  productRequestForDispense: ProductRequest | null = null;
  productRequestDetailOpen = false;
  currentProductRequest: ProductRequest | null = null;
  productRequestDetailContext: ProductRequestDetailContext | null = null;
  helpLink = '';
  showNewClientDeposit = false;
  showNewFormRequest = false;

  constructor(private store: Store<AppState>, private router: Router, private environmentService: EnvironmentService) { }

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
    this.subscribeToNewConversationOpen();
    this.subscribeToProductRequestOpen();
    this.subscribeToApproveProductRequest();
    this.subscribeToRejectProductRequest();
    this.subscribeToProductRequestPaymentCreate();
    this.subscribeToProductRequestPaidConfirmation();
    this.subscribeToDispenseProductRequest();
    this.subscribeToProductRequestDetailOpen();
    this.getHelpLink();
    this.subscribeToNewClientDepositOpen();
    this.subscribeToNewFormRequestOpen();

    this.subscribeToRouteChanges();
  }


  subscribeToRouteChanges(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.closeAllModals();
      }
    });
  }

  closeAllModals(): void {
    this.productRequestOpen = false;
    this.approveProductRequestOpen = false;
    this.rejectProductRequestOpen = false;
    this.createProductRequestPaymentOpen = false;
    this.productRequestPaidConfirmationOpen = false;
    this.productRequestDispenseOpen = false;
    this.productRequestDetailOpen = false;
  }

  getHelpLink(): void {
    this.helpLink = `${this.environmentService.get('helpUrl')}/learn/section/digital-practice`;
  }

  subscribeToNewConversationOpen(): void {
    this.store.pipe(select(isNewConversationOpen)).pipe(takeWhile(() => this.alive)).subscribe((open) => {
      setTimeout(() => {
        this.showNewConversation = open;
      }, 0);
    });
  }

  subscribeToProductRequestOpen(): void {
    this.store.pipe(select(isProductRequestOpen)).pipe(takeWhile(() => this.alive)).subscribe((open) => {
      setTimeout(() => {
        this.productRequestOpen = open;
      }, 0);
    });
  }

  subscribeToApproveProductRequest(): void {
    this.store.pipe(select(getProductRequestApprovalOpen)).pipe(takeWhile(() => this.alive)).subscribe((open) => {
      setTimeout(() => {
        this.approveProductRequestOpen = open;
      }, 0);
    });

    this.store.pipe(select(getProductRequestForApproval)).pipe(takeWhile(() => this.alive)).subscribe((productRequest) => {
      setTimeout(() => {
        this.productRequestToApprove = productRequest;
      }, 0);
    });
  }

  subscribeToRejectProductRequest(): void {
    this.store.pipe(select(getProductRequestRejectionOpen)).pipe(takeWhile(() => this.alive)).subscribe((open) => {
      setTimeout(() => {
        this.rejectProductRequestOpen = open;
      }, 0);
    });

    this.store.pipe(select(getProductRequestForRejection)).pipe(takeWhile(() => this.alive)).subscribe((productRequest) => {
      setTimeout(() => {
        this.productRequestToReject = productRequest;
      }, 0);
    });
  }

  subscribeToProductRequestPaymentCreate(): void {
    this.store.pipe(select(getProductRequestPaymentCreateOpen)).pipe(takeWhile(() => this.alive)).subscribe((open) => {
      setTimeout(() => {
        this.createProductRequestPaymentOpen = open;
      }, 0);
    });

    this.store.pipe(select(getProductRequestForPaymentCreate)).pipe(takeWhile(() => this.alive)).subscribe((productRequest) => {
      setTimeout(() => {
        this.productRequestToCreatePayment = productRequest;
      }, 0);
    });
  }

  subscribeToProductRequestPaidConfirmation(): void {
    this.store.pipe(select(getProductRequestPaidConfirmationOpen)).pipe(takeWhile(() => this.alive)).subscribe((open) => {
      setTimeout(() => {
        this.productRequestPaidConfirmationOpen = open;
      }, 0);
    });

    this.store.pipe(select(getProductRequestPaidConfirmationContext)).pipe(takeWhile(() => this.alive)).subscribe((context) => {
      setTimeout(() => {
        if (context) {
          this.productRequestPaidConfirmationContext = context;
        }
      }, 0);
    });

    this.store.pipe(select(getProductRequestForPaidConfirmation)).pipe(takeWhile(() => this.alive)).subscribe((productRequest) => {
      setTimeout(() => {
        this.productRequestForPaidConfirmation = productRequest;
      }, 0);
    });
  }

  subscribeToDispenseProductRequest(): void {
    this.store.pipe(select(getDispenseProductRequestOpen)).pipe(takeWhile(() => this.alive)).subscribe((open) => {
      setTimeout(() => {
        this.productRequestDispenseOpen = open;
      }, 0);
    });

    this.store.pipe(select(getProductRequestForDispense)).pipe(takeWhile(() => this.alive)).subscribe((productRequest) => {
      setTimeout(() => {
        this.productRequestForDispense = productRequest;
      }, 0);
    });
  }

  subscribeToProductRequestDetailOpen(): void {
    this.store.pipe(select(getProductRequestDetailOpen)).pipe(takeWhile(() => this.alive)).subscribe((open) => {
      setTimeout(() => {
        this.productRequestDetailOpen = open;
      }, 0);
    });


    this.store.pipe(select(getCurrentProductRequest)).pipe(takeWhile(() => this.alive)).subscribe((productRequest) => {
      setTimeout(() => {
        this.currentProductRequest = productRequest;
      }, 0);
    });
  }

  subscribeToNewClientDepositOpen(): void {
    this.store.pipe(select(getNewClientDepositOpen)).pipe(takeWhile(() => this.alive)).subscribe((open) => {
      setTimeout(() => {
        this.showNewClientDeposit = open;
      }, 0);
    });
  }

  subscribeToNewFormRequestOpen(): void {
    this.store.pipe(select(isNewFormRequestOpen)).pipe(takeWhile(() => this.alive)).subscribe((open) => {
      setTimeout(() => {
        this.showNewFormRequest = open;
      }, 0);
    });
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store.pipe(select(getCurrentPractice)).pipe(
      takeWhile(() => this.alive)
    );

    this.practice$.subscribe(practice => {
      if (practice) {
        this.practice = practice;
      }
    });
  }

  handleNewConversationDialogClosed(): void {
    this.store.dispatch(CloseNewConversation());
    this.store.dispatch(ClearNewConversationError());
  }

  handleProductRequestDialogClosed(): void {
    this.store.dispatch(CloseProductRequest());
  }

  handleApproveProductRequestDialogClosed(): void {
    this.store.dispatch(CloseProductRequestApproval());
  }

  handleRejectProductRequestDialogClosed(): void {
    this.store.dispatch(CloseProductRequestRejection());
  }

  handleProductRequestCreatePaymentDialogClosed(): void {
    this.store.dispatch(CloseProductRequestPaymentCreate());
  }

  handleProductRequestForPaidConfirmationDialogClosed(): void {
    this.store.dispatch(CloseProductRequestPaidConfirmation());
  }

  handleDispenseProductRequestDialogClosed(): void {
    this.store.dispatch(CloseDispenseProductRequest());
  }

  handleProductRequestDetailDialogClosed(): void {
    this.store.dispatch(ResetShareClientToPms());
    this.store.dispatch(ResetSharePatientToPms());
    this.store.dispatch(CloseProductRequestDetail());
  }

  handleNewClientDepositClosed(): void {
    this.store.dispatch(CloseNewClientDeposit());
  }

  handleNewFormRequestClosed(): void {
    this.store.dispatch(CloseFormRequest());
  }
}
