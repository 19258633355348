import { HostListener, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { MessageDto } from "../interfaces/dto/message.dto";
import { map } from "rxjs/operators";
import { SocketService } from "../services/socket.service";
import { TokenService } from "../services/token.service";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { SystemState } from "../state/reducers";
import {
  AddWebsocketConnection,
  RemoveWebsocketConnection,
  SetWebsocketHealthy,
} from "../state/actions";
import { CommentDto } from "../interfaces/dto/comment.dto";
import { ConversationDto } from "../interfaces/dto/conversation.dto";
import { NotificationDto } from "../notifications/dto/notification.dto";
import { MessageType } from "../enums/message-type";
import { Media } from "../models/Media";
import { MediaDto } from "../interfaces/dto/media.dto";
import { ProductRequest } from "../models/ProductRequest";
import { ProductRequestDto } from "../interfaces/dto/product-request.dto";
import { UserDto } from "../interfaces/dto/user.dto";
import { Template } from "../models/Template";
import { TemplateMergeFieldDo } from "../interfaces/template-merge-field.do.interface";

@Injectable({
  providedIn: "root",
})
export class WebsocketService {
  onConnect$ = new BehaviorSubject(false);
  disconnectTimeout?: ReturnType<typeof setTimeout>;
  pageHidden = false;
  websocketConnected = true;

  constructor(
    private socket: SocketService,
    private tokenService: TokenService,
    private router: Router,
    private store: Store<SystemState>,
  ) {
    this.bindSocketIoEvents();
  }

  bindSocketIoEvents(): void {
    this.socket.on("connect", () => {
      this.onConnect$.next(true);
      this.websocketConnected = true;

      if (this.disconnectTimeout) {
        clearTimeout(this.disconnectTimeout);
      }
      this.store.dispatch(SetWebsocketHealthy({ healthy: true }));
    });

    this.socket.on("disconnect", () => {
      this.onConnect$.next(false);
      this.websocketConnected = false;
      this.startWsDisconnectedTimeout();
    });
  }

  @HostListener("document:visibilitychange", ["$event"])
  visibilityChange(): void {
    if (document.hidden) {
      this.pageHidden = true;
    } else {
      this.pageHidden = false;
    }
  }

  startWsDisconnectedTimeout(): void {
    this.disconnectTimeout = setTimeout(() => {
      if (!this.pageHidden) {
        this.store.dispatch(SetWebsocketHealthy({ healthy: false }));
      }
    }, 30000);
  }

  updateLastSeen(id: string, userId: string, type: string): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("update-last-seen-conversation", { id, userId, type });
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  joinConversation(
    conversationId: string,
    userId: string,
    sendUser: boolean,
  ): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("join-conversation", {
          conversationId,
          userId,
          sendUser,
        });
        this.store.dispatch(
          AddWebsocketConnection({
            roomName: "conversation-" + conversationId,
          }),
        );
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  leaveConversation(conversationId: string, userId: string): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("leave-conversation", { conversationId, userId });
        this.store.dispatch(
          RemoveWebsocketConnection({
            roomName: "conversation-" + conversationId,
          }),
        );
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  joinMediaLibrary(practiceId: string): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("join-media-library", { practiceId });
        this.store.dispatch(
          AddWebsocketConnection({ roomName: "practice-media-" + practiceId }),
        );
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  leaveMediaLibrary(practiceId: string): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("leave-media-library", { practiceId });
        this.store.dispatch(
          RemoveWebsocketConnection({
            roomName: "practice-media-" + practiceId,
          }),
        );
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  getMessages(): Observable<MessageDto> {
    return this.socket.fromEvent<string>("deliver_message").pipe(
      map((data: string) => {
        return JSON.parse(data);
      }),
    );
  }

  getUnsentMessages(): Observable<MessageDto> {
    return this.socket.fromEvent<string>("unsend_message").pipe(
      map((data: string) => {
        return JSON.parse(data);
      }),
    );
  }

  getMessageUpdates(): Observable<MessageDto> {
    return this.socket.fromEvent<string>("update_message").pipe(
      map((data: string) => {
        return JSON.parse(data);
      }),
    );
  }

  getMessageDeletes(): Observable<string> {
    return this.socket.fromEvent<string>("delete_message").pipe(
      map((data: string) => {
        return JSON.parse(data);
      }),
    );
  }

  getVideoCompressionJobComplete(): Observable<string> {
    return this.socket.fromEvent<string>("video_compression_job_complete");
  }

  getMediaLibraryVideoCompressionJobComplete(): Observable<{
    jobId: string;
    media: MediaDto;
  }> {
    return this.socket.fromEvent<{ jobId: string; media: MediaDto }>(
      "media_library_video_compression_job_complete",
    );
  }

  getConversationUpdates(): Observable<ConversationDto> {
    return this.socket.fromEvent<string>("update_conversation").pipe(
      map((data: string) => {
        return JSON.parse(data);
      }),
    );
  }

  sendMessage(
    conversationId: string,
    content: string,
    type: MessageType,
    selectedTemplate?: Template,
    templateMedia?: Media,
    selectedTemplateMergeFields?: TemplateMergeFieldDo[],
    buttonLink?: string,
  ): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("send_message", {
          conversationId: Number(conversationId),
          content,
          type,
          selectedTemplate,
          templateMedia,
          selectedTemplateMergeFields,
          buttonLink,
        });
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  sendInitialiseMessage(conversationId: string): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("send_initialise_message", {
          conversationId: Number(conversationId),
        });
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  sendReinitialiseMessage(conversationId: string): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("send_reinitialise_message", {
          conversationId: Number(conversationId),
        });
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  joinComments(conversationId: string): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("join-comments", { conversationId });
        this.store.dispatch(
          AddWebsocketConnection({ roomName: "comments-" + conversationId }),
        );
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  leaveComments(conversationId: string): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("leave-comments", { conversationId });
        this.store.dispatch(
          RemoveWebsocketConnection({ roomName: "comments-" + conversationId }),
        );
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  getViewers(): Observable<UserDto[]> {
    return this.socket.fromEvent<string>("current_viewers").pipe(
      map((data: string) => {
        return JSON.parse(data);
      }),
    );
  }

  getViewersFromProductRequest(): Observable<UserDto[]> {
    return this.socket
      .fromEvent<string>("current_viewers_product_request")
      .pipe(
        map((data: string) => {
          return JSON.parse(data);
        }),
      );
  }

  getComments(): Observable<CommentDto> {
    return this.socket.fromEvent<string>("deliver_comment").pipe(
      map((data: string) => {
        return JSON.parse(data);
      }),
    );
  }

  sendComment(
    conversationId: string | null,
    productRequestId: string | null,
    content: string,
  ): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("send_comment", {
          conversationId: conversationId ? Number(conversationId) : null,
          productRequestId: productRequestId ? Number(productRequestId) : null,
          content: JSON.parse(content),
        });
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  joinNotificationsChannel(userId: number): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("join-notification-channel", { userId });
        this.store.dispatch(
          AddWebsocketConnection({ roomName: `notifications-user-${userId}` }),
        );
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  leaveNotificationsChannel(userId: number): void {
    this.socket.emit("leave-notification-channel", { userId });
    this.store.dispatch(
      RemoveWebsocketConnection({ roomName: `notifications-user-${userId}` }),
    );
  }

  getNotifications(): Observable<{
    notification: NotificationDto;
    showToast: boolean;
  }> {
    return this.socket.fromEvent<string>("new_notification").pipe(
      map((data: string) => {
        return JSON.parse(data);
      }),
    );
  }

  joinPayments(practiceId: string): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("join-payments", { practiceId });
        this.store.dispatch(
          AddWebsocketConnection({ roomName: "payments-" + practiceId }),
        );
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  leavePayments(practiceId: string): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("leave-payments", { practiceId });
        this.store.dispatch(
          RemoveWebsocketConnection({ roomName: "payments-" + practiceId }),
        );
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  getPaymentsUpdates(): Observable<void> {
    return this.socket.fromEvent<string>("update_payments").pipe(
      map(() => {
        return;
      }),
    );
  }

  joinDayList(practiceId: string): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("join-daylist", { practiceId });
        this.store.dispatch(
          AddWebsocketConnection({ roomName: "daylist-" + practiceId }),
        );
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  leaveDayList(practiceId: string): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("leave-daylist", { practiceId });
        this.store.dispatch(
          RemoveWebsocketConnection({ roomName: "daylist-" + practiceId }),
        );
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  getDaylistUpdates(): Observable<void> {
    return this.socket.fromEvent<string>("update_daylist").pipe(
      map(() => {
        return;
      }),
    );
  }

  joinProductRequest(
    productRequestId: string,
    userId: string,
    sendUser: boolean,
  ): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("join-product-request", {
          productRequestId,
          userId,
          sendUser,
        });
        this.store.dispatch(
          AddWebsocketConnection({
            roomName: "product-request-" + productRequestId,
          }),
        );
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  leaveProductRequest(productRequestId: string, userId: string): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("leave-product-request", { productRequestId, userId });
        this.store.dispatch(
          RemoveWebsocketConnection({
            roomName: "product-request-" + productRequestId,
          }),
        );
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  getProductRequestComments(): Observable<CommentDto> {
    return this.socket
      .fromEvent<string>("deliver_product_request_comment")
      .pipe(
        map((data: string) => {
          return JSON.parse(data);
        }),
      );
  }

  joinProductRequestList(practiceId: string): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("join-product-request-list", { practiceId });
        this.store.dispatch(
          AddWebsocketConnection({
            roomName: "product-request-list-" + practiceId,
          }),
        );
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  leaveProductRequestList(practiceId: string): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("leave-product-request-list", { practiceId });
        this.store.dispatch(
          RemoveWebsocketConnection({
            roomName: "product-request-list-" + practiceId,
          }),
        );
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  getProductRequestListUpdates(): Observable<ProductRequestDto> {
    return this.socket.fromEvent<string>("deliver_product_request_update").pipe(
      map((data: string) => {
        return JSON.parse(data);
      }),
    );
  }

  joinPractice(practiceId: string): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("join-practice", { practiceId });
        this.store.dispatch(
          AddWebsocketConnection({ roomName: "practice-" + practiceId }),
        );
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  leavePractice(practiceId: string): void {
    this.tokenService.checkTokens().subscribe((valid) => {
      if (valid) {
        this.socket.emit("leave-practice", { practiceId });
        this.store.dispatch(
          RemoveWebsocketConnection({ roomName: "practice-" + practiceId }),
        );
      } else {
        this.router.navigateByUrl("auth/login");
      }
    });
  }

  getPmsStatusUpdates(): Observable<boolean> {
    return this.socket.fromEvent<string>("practicePmsHealthUpdate").pipe(
      map((data: string) => {
        const parsedData = JSON.parse(data);
        console.log(parsedData);
        return parsedData.pmsHealthy;
      }),
    );
  }
}
