import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../../models/User';
import {EnvironmentService} from '../../../services/environment.service';
import {Store} from '@ngrx/store';
import {BlockSender} from '../../../conversation/state/actions';
import {Conversation} from '../../../models/Conversation';
import {Channel} from '../../../enums/channel';
import {ConversationUser} from '../../../models/ConversationUser';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'block-sender',
  templateUrl: './block-sender.component.html',
  styleUrls: ['./block-sender.component.scss']
})
export class BlockSenderComponent implements OnInit {
  @Output() closed = new EventEmitter();
  @Input() conversation: Conversation | null = null;
  @Input() conversations?: Conversation[];
  helpLink = '';
  loading = false;

  constructor(private store: Store, private environmentService: EnvironmentService, public messageService: MessageService) {

  }

  ngOnInit(): void {
    this.getHelpLink();
  }

  closeBlockSender(): void {
    this.closed.emit();
  }

  blockSender(): void {
    if (this.conversation) {
      this.loading = true;
      this.store.dispatch(BlockSender({conversations: [this.conversation]}));
    }

    if (this.conversations) {
      this.loading = true;
      this.store.dispatch(BlockSender({conversations: this.conversations}));
    }
  }

  getHelpLink(): void {
    this.helpLink = `${this.environmentService.get('helpUrl')}/learn/section/digital-practice`;
  }
}
