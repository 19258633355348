import * as actions from './actions';
import * as userActions from '../../users/state/actions';
import { Action, createReducer, on } from '@ngrx/store';
import { User } from '../../models/User';
import {StoreUnreadPosts} from "./actions";
import {HelpPost} from "../../interfaces/help-post.interface";

export interface AuthState {
  user: User | null;
  unreadHelpPosts: HelpPost[];
}

export const initialState: AuthState = {
  user: null,
  unreadHelpPosts: [],
};

const authReducer = createReducer(
  initialState,
  on(actions.StoreUser, (state, payload) => ({
    ...state,
    user: payload.user
  })),
  on(actions.RemoveUser, (state, payload) => ({
    ...state,
    user: null
  })),
  on(userActions.CompleteTourSuccess, (state, payload) => ({
    ...state,
    user: payload.user
  })),
  on(actions.StoreUnreadPosts, (state, payload) => ({
    ...state,
    unreadHelpPosts: payload.posts
  }))
);

export function reducer(state: AuthState | undefined, action: Action): AuthState {
  return authReducer(state, action);
}
