import { Pipe, PipeTransform } from '@angular/core';
import {FormFieldPrefillOption} from '../enums/form-field-prefill-option.enum';
import {getFieldPrefillValue} from '../helpers/get-field-prefill-value';
import {Client} from '../models/Client';
import {Patient} from "../models/Patient";

@Pipe({name: 'getFieldPrefillValue'})
export class GetFieldPrefillValuePipe implements PipeTransform {
  transform(prefillWith: string, client: Client, patient: Patient | null): string {
    return getFieldPrefillValue(prefillWith as FormFieldPrefillOption, client, patient);
  }
}
