import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";

import {
  BehaviorSubject,
  ConnectableObservable,
  first,
  interval,
  Observable,
  Subscription,
} from "rxjs";
import { take, takeWhile } from "rxjs/operators";

import { Comment } from "../../../models/Comment";
import { Conversation } from "../../../models/Conversation";
import { Message } from "../../../models/Message";
import { User } from "../../../models/User";

import {
  AddComment,
  AddCommentSuccess,
  AddTourConversation,
  AddTourStaffComments,
  AddWatcher,
  ClearClient,
  ClearConversation,
  ClearCurrentConversation,
  ClearPatient,
  ClearPatientHistory,
  ClearPreviousConversations,
  ClearStaffComments,
  GetClient,
  GetClientSuccess,
  GetConversation,
  GetConversationStatuses,
  GetPatient,
  GetPatientHistory,
  GetPatientSuccess,
  GetStaffComments,
  MarkConversationsAsRead,
  RefreshConversationClient,
  RemoveClientFromConversation,
  RemovePatientFromConversation,
  RemoveWatcher,
  ResetShareClientToPms,
  ResetSharePatientToPms,
  SetConversationAssignee,
  SetConversationClient,
  SetConversationOwner,
  SetConversationPatient,
  SetConversationStatus,
  SetCurrentConversation,
  SetPreviousConversationsLoaded,
  ShareClientToPms,
  SharePatientToPms,
  StartClientLoading,
  UpdateConversationsFilters,
  UpdateConversationSummary,
} from "../../state/actions";
import {
  AddTourMessages,
  ClearMessages,
  ClearVideoSending,
  GetMessages,
} from "../../../messages/state/actions";
import {
  getClientLoading,
  getConversation,
  getConversationClient,
  getConversationPatient,
  getConversationStatuses,
  getExternalConversationUser,
  getPatientHistory,
  getStaffCommentsReversed,
  isClientShared,
  isPatientHistoryLoading,
  isPatientLoading,
  isPatientShared,
  isReloadingClient,
} from "../../state/selectors";

import { getMessagesReversed } from "../../../messages/state/selectors";
import { WebsocketService } from "../../websocket.service";
import { ConversationStatus } from "../../../models/ConversationStatus";
import {
  GetPracticeStaff,
  GetPracticeTemplates,
  SetSelectedPractice,
} from "../../../practices/state/actions";
import { Client } from "../../../models/Client";
import { Patient } from "../../../models/Patient";
import { Channel } from "../../../enums/channel";
import { ConversationUser } from "../../../models/ConversationUser";
import { AppState } from "../../../state/reducers";
import { getUser } from "../../../auth/state/selectors";
import { getPatients } from "../../../patients/state/selectors";
import { CommentAdapter } from "../../../adapters/comment.adapter";
import { HistoryItem } from "../../../models/HistoryItem";
import { ConversationAdapter } from "../../../adapters/conversation.adapter";
import {
  ClosePaymentRequest,
  OpenPaymentRequest,
  SubmitPaymentRequest,
} from "../../../payments/state/actions";
import {
  getPaymentRequestLoading,
  getSubmitPaymentRequestFailed,
  isPaymentRequestOpen,
} from "../../../payments/state/selectors";
import { PaymentRequest } from "../../../interfaces/payment-request";
import { Group } from "../../../models/Group";
import {
  canChangePage,
  getTourMode,
  isFullScreen,
} from "../../../state/selectors";
import { getPracticeStaff } from "src/app/practices/state/selectors";
import { getCurrentPractice } from "src/app/practices/state/selectors";
import { Practice } from "src/app/models/Practice";
import { ConversationPageTab } from "src/app/enums/conversation-page-tabs";
import { ComponentCanDeactivate } from "../../../guards/pending-changes.guard";
import { NoClientPanelComponent } from "src/app/ui/components/no-client-panel/no-client-panel.component";
import {
  ClearPatients,
  IncrementPatientPage,
  ResetPatientPage,
} from "src/app/patients/state/actions";
import { practiceHasFeature } from "src/app/helpers/practice-has-feature";
import { PracticeFeature } from "src/app/enums/practice-feature";
import { MessageType } from "src/app/enums/message-type";
import { Title } from "@angular/platform-browser";
import { PMS } from "../../../enums/pms";
import { subMinutes } from "date-fns";
import { Template } from "../../../models/Template";
import { Role } from "../../../enums/role";
import { defaultConversationFilters } from "../../../constants/default-conversation-filters.constants";
import { Contact } from "../../../models/Contact";
import { UserAdapter } from "../../../adapters/user.adapter";
import { CookieService } from "ngx-cookie-service";
import { getViewersExludingSelf } from "../../../viewers/state/selectors";
import {
  ClearViewers,
  GetViewers,
  GetViewersSuccess,
} from "../../../viewers/state/actions";
import { ViewerType } from "../../../enums/viewers-type";
import Delta from "quill-delta";

@Component({
  selector: "app-conversation",
  templateUrl: "./conversation.component.html",
  styleUrls: ["./conversation.component.scss"],
})
export class ConversationPage
  implements OnInit, OnDestroy, ComponentCanDeactivate
{
  @ViewChild("noClientPanel", { static: false })
  noClientPanel?: NoClientPanelComponent;
  alive = true;
  conversationId?: string | null;
  conversation$: Observable<Conversation | null> = new BehaviorSubject(null);
  conversation?: Conversation;
  viewers$: Observable<User[] | null> = new BehaviorSubject(null);
  viewers: User[] = [];
  messages$?: Observable<Message[] | null>;
  messages: Message[] | null = [];
  staffComments$: Observable<Comment[]> = new BehaviorSubject([]);
  conversationStatuses$?: Observable<ConversationStatus[]>;
  client$?: Observable<Client | null>;
  client?: Client | null;
  patient?: Patient | null;
  clientSub$?: Subscription;
  patientSub$?: Subscription;
  patient$?: Observable<Patient | null>;
  externalUser$ = new BehaviorSubject<ConversationUser | null>(null);
  clientLoading$?: Observable<boolean>;
  socketComments$?: Subscription;
  socketConversation$?: Subscription;
  authUser$?: Observable<User | null>;
  authUser?: User;
  clientShared$?: Observable<boolean>;
  patientShared$?: Observable<boolean>;
  availablePatients$?: Observable<Patient[]>;
  patientLoading$?: Observable<boolean>;
  patientHistoryLoading$?: Observable<boolean>;
  patientHistory$?: Observable<HistoryItem[]>;
  externalConversationUser$?: Observable<User | null>;
  userClientMismatch$?: Observable<boolean | null>;
  newPaymentFailed$?: Observable<boolean>;
  paymentRequestLoading$?: Observable<boolean>;
  paymentRequestOpen$?: Observable<boolean>;
  clientLoaded = false;
  patientLoaded = false;
  activeTab: ConversationPageTab = ConversationPageTab.CHAT;
  shareClientLoading = false;
  sharePatientLoading = false;
  paymentRequestFailed = false;
  device: string;
  resizeTimeout?: any;
  practiceUsers$?: Observable<User[]>;
  currentPractice$?: Observable<Practice | null>;
  currentPractice?: Practice | null;
  tabs = ConversationPageTab;
  showActionsPopup = false;
  chatIsDisabled = true;
  chatIsAwaitingResponse = false;
  paymentsFeatureEnabled = false;
  productRequestFeatureEnabled = false;
  isReloadingClient$?: Observable<boolean>;
  templates$?: Observable<Template[]>;
  templates: Template[] = [];
  paymentsDisabled = true;
  clientInitialLoaded = false;
  paymentSyncingFeatureEnabled = false;
  practices: Practice[] = [];
  markedAsReadOnOpen = false;
  messageType = MessageType;
  paymentsFailedToSync = 0;
  checkForUnsyncedPaymentsInterval?: Subscription;
  channels = Channel;
  fullScreen$: Observable<boolean>;
  practiceAutomaticallyUpdatedOnLoad = false;
  canChangePage = true;
  changingClient = false;
  contact?: Contact;
  getUserSocket$?: Subscription;
  removeUserSocket$?: Subscription;
  users: User[] = [];
  topBarMessage: string = "";
  showUsers: boolean = false;
  impersonator: string = "";

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private websocketService: WebsocketService,
    private commentAdapter: CommentAdapter,
    private conversationAdapter: ConversationAdapter,
    private titleService: Title,
    private userAdapter: UserAdapter,
    private cookieService: CookieService,
  ) {
    const title = "Digital Practice | Conversation";
    this.titleService.setTitle(title);

    this.conversationId = this.route.snapshot.paramMap.get("id");
    this.device = this.getDevice();

    this.fullScreen$ = this.store
      .pipe(select(isFullScreen))
      .pipe(takeWhile(() => this.alive));
  }

  @HostListener("window:resize")
  handleResize(): void {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      const newDevice = this.getDevice();

      if (this.device === newDevice) {
        return;
      }

      this.activeTab = ConversationPageTab.CHAT;
      this.device = newDevice;
    }, 100);
  }

  @HostListener("window:beforeunload", ["$event"])
  canDeactivate(): Observable<boolean> | boolean {
    if (this.canChangePage) {
      return true;
    }

    return false;
  }

  @HostListener("window:beforeunload", ["$event"])
  leavePage($event: any): void {
    if (!this.canDeactivate()) {
      $event.returnValue =
        "If you leave now, the files you have started sending will be lost. Are you sure?";
    }
  }

  getDevice(): string {
    if (window.innerWidth <= 822) {
      return "mobile";
    } else if (window.innerWidth <= 1050) {
      return "tablet-small";
    } else if (window.innerWidth <= 1300) {
      return "tablet";
    }

    return "desktop";
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.destroy();
      setTimeout(() => {
        this.init();
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.destroy();
    this.alive = false;
  }

  init(): void {
    this.clearVideoSending();
    this.subscribeToCurrentConversation();
    this.subscribeToUrlFragments();
    this.subscribeToConversationMessages();
    this.subscribeToStaffComments();
    this.subscribeToConversationStatuses();
    this.subscribeToClient();
    this.subscribeToClientLoading();
    this.subscribeToPatientLoading();
    this.subscribeToAuthUser();
    this.subscribeToClientShared();
    this.subscribeToAvailablePatients();
    this.subscribeToPatient();
    this.subscribeToPatientShared();
    this.subscribeToPatientHistoryLoading();
    this.subscribeToPatientHistory();
    this.subscribeToExternalConversationUser();
    this.subscribeToNewPayment();
    this.subscribeToPracticeStaff();
    this.subscribeToCurrentPractice();
    this.subscribeToIsReloadingClient();
    this.subscribeToCanChangePage();
    this.store.dispatch(ClearClient());
    this.store.dispatch(ClearPatient());
    this.store.dispatch(ClearPatients());
    this.store.dispatch(ResetPatientPage());
    this.store.dispatch(ClearPreviousConversations());
    this.store.dispatch(SetPreviousConversationsLoaded({ loaded: false }));
    this.checkForImpersonator();
    this.subscribeToViewers();

    this.conversationId = this.route.snapshot.paramMap.get("id");

    this.store
      .select(getTourMode)
      .pipe(take(1))
      .subscribe((tourMode) => {
        if (tourMode && this.authUser && this.conversationId === "0") {
          // We are in the tour
          this.store.dispatch(AddTourConversation({ user: this.authUser }));
          this.store.dispatch(AddTourMessages());
          this.store.dispatch(AddTourStaffComments());
        } else if (this.conversationId === "0" && !tourMode) {
          // We have come to /conversation/0 when not in the tour - get me out of here
          this.router.navigateByUrl("day-list");
        }
      });

    if (this.conversationId && this.conversationId !== "0") {
      this.store.dispatch(SetCurrentConversation({ id: this.conversationId }));
      this.store.dispatch(GetConversation({ id: this.conversationId }));
      this.store.dispatch(GetMessages({ conversationId: this.conversationId }));
      this.store.dispatch(
        GetStaffComments({ conversationId: this.conversationId }),
      );
      this.store.dispatch(ResetShareClientToPms());
    }

    this.websocketService.onConnect$.subscribe((connected) => {
      if (connected && this.conversationId && this.authUser) {
        this.websocketService.joinComments(this.conversationId);
      }
    });

    this.socketComments$ = this.websocketService
      .getComments()
      .subscribe((data) => {
        this.store.dispatch(
          AddCommentSuccess({ comment: this.commentAdapter.run(data) }),
        );
      });

    this.store.dispatch(GetConversationStatuses());

    this.checkForUnsyncedPaymentsInterval = interval(30000).subscribe(() => {
      this.checkForUnsyncedPayments();
    });

    this.websocketService
      .getViewers()
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        const viewers = data.map((viewer) => this.userAdapter.run(viewer));
        this.store.dispatch(GetViewersSuccess({ viewers: viewers }));
      });
  }

  checkForImpersonator(): void {
    const impersonator = this.cookieService.get("impersonate");
    if (impersonator) {
      this.impersonator = impersonator;
    }
  }

  subscribeToViewers(): void {
    this.viewers$ = this.store
      .pipe(select(getViewersExludingSelf))
      .pipe(takeWhile(() => this.alive));

    this.viewers$.subscribe((viewers) => {
      if (viewers) {
        this.viewers = viewers;
      }
    });
  }

  destroy(): void {
    this.clientLoaded = false;
    this.patientLoaded = false;

    if (this.conversationId && this.authUser) {
      this.websocketService.leaveConversation(
        this.conversationId,
        this.authUser.id,
      );
      this.websocketService.leaveComments(this.conversationId);
    }

    this.socketComments$?.unsubscribe();
    this.socketConversation$?.unsubscribe();
    this.getUserSocket$?.unsubscribe();
    this.removeUserSocket$?.unsubscribe();
    this.clientSub$?.unsubscribe();
    this.patientSub$?.unsubscribe();

    this.store.dispatch(ClearCurrentConversation());
    this.store.dispatch(ClearClient());
    this.store.dispatch(ClearPatient());
    this.store.dispatch(ClearConversation());
    this.store.dispatch(ClearStaffComments());
    this.store.dispatch(ClearMessages());
    this.store.dispatch(ResetShareClientToPms());
    this.store.dispatch(ResetSharePatientToPms());
    this.store.dispatch(ClearPatientHistory());
    this.store.dispatch(ClearViewers());

    this.checkForUnsyncedPaymentsInterval?.unsubscribe();
  }

  updateCurrentPracticeIfNecessary(): void {
    if (
      this.currentPractice &&
      this.conversation &&
      this.conversation.practice &&
      this.conversation.practice.id !== "0" &&
      (!this.currentPractice ||
        this.conversation.practice.id !== this.currentPractice.id)
    ) {
      this.store.dispatch(
        SetSelectedPractice({ practice: this.conversation.practice }),
      );
    }
  }

  subscribeToCanChangePage(): void {
    this.store
      .pipe(select(canChangePage))
      .pipe(takeWhile(() => this.alive))
      .subscribe((canChange) => (this.canChangePage = canChange));
  }

  getViewers(): void {
    if (this.conversation) {
      this.store.dispatch(
        GetViewers({
          id: this.conversation.id,
          modelType: ViewerType.CONVERSATION,
        }),
      );
    }
  }

  subscribeToCurrentConversation(): void {
    this.conversation$ = this.store
      .pipe(select(getConversation))
      .pipe(takeWhile(() => this.alive));

    this.conversation$.subscribe((conversation) => {
      this.contact = undefined;

      if (conversation) {
        this.conversation = conversation;
        this.getViewers();
        this.updateChatIsDisabled();
        this.updatePaymentsDisabled();
        this.updateChatIsAwaitingResponse();
        this.markReadIfFeatureEnabled();

        this.checkForUnsyncedPayments();

        this.updateCurrentPracticeIfNecessary();

        const externalUser = conversation.users
          ? conversation.users.find((user) => user.channel !== Channel.INTERNAL)
          : null;
        if (externalUser) {
          this.externalUser$.next(externalUser);

          if (externalUser.phone) {
            this.contact = {
              facebookLinked: false,
              instagramLinked: false,
              name: externalUser.fullName,
              value: externalUser.phone,
              typeCode: "0",
            };
          }
        }

        if (!this.clientLoaded && conversation.client) {
          // This is for the mock client that is used for the tour!
          if (conversation.client.id === "0") {
            this.store.dispatch(
              GetClientSuccess({ client: conversation.client }),
            );
          } else {
            this.store.dispatch(StartClientLoading());
            this.store.dispatch(
              GetClient({ clientId: conversation.client.id }),
            );
            this.clientLoaded = true;
          }
        } else if (this.clientLoaded && !conversation.client) {
          this.clientLoaded = false;
          this.patientLoaded = false;
          this.shareClientLoading = false;
          this.sharePatientLoading = false;
          this.store.dispatch(ResetShareClientToPms());
          this.store.dispatch(ClearClient());
          this.store.dispatch(ResetSharePatientToPms());
          this.store.dispatch(ClearPatient());
          this.store.dispatch(ClearPatientHistory());
        }

        if (
          !this.patientLoaded &&
          conversation.patient &&
          conversation.patient.id
        ) {
          if (conversation.patient.id === "0") {
            this.store.dispatch(
              GetPatientSuccess({ patient: conversation.patient }),
            );
          } else {
            this.store.dispatch(
              GetPatient({ patientId: conversation.patient.id }),
            );
            this.patientLoaded = true;
          }
        } else if (this.patientLoaded && !conversation.patient) {
          this.patientLoaded = false;
          this.sharePatientLoading = false;
          this.store.dispatch(ResetSharePatientToPms());
          this.store.dispatch(ClearPatient());
          this.store.dispatch(ClearPatientHistory());
        }
      }
    });
  }

  clearVideoSending(): void {
    this.store.dispatch(ClearVideoSending());
  }

  subscribeToUrlFragments(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment === "conversation") {
        this.switchTab(ConversationPageTab.TICKET);
      }

      if (fragment === "team-chat") {
        this.switchTab(ConversationPageTab.TEAM_CHAT);
      }
    });
  }

  subscribeToCurrentPractice(): void {
    this.currentPractice$ = this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive));
    this.currentPractice$.subscribe((practice) => {
      this.currentPractice = practice;
      this.showUsers = practiceHasFeature(
        practice,
        PracticeFeature.SHOW_TEAM_LOCATION,
      );
      this.updateFeaturesEnabled();
      this.markReadIfFeatureEnabled();
      this.checkForUnsyncedPayments();
      this.store.dispatch(GetPracticeTemplates());
    });

    this.currentPractice$.pipe(first()).subscribe((practice) => {
      // only do this on page load, after that the practice would be changing because of user action
      this.updateCurrentPracticeIfNecessary();
    });
  }

  subscribeToClient(): void {
    this.client$ = this.store
      .pipe(select(getConversationClient))
      .pipe(takeWhile(() => this.alive));

    this.clientSub$ = this.client$.subscribe((client) => {
      this.client = client;

      if (client) {
        this.clientInitialLoaded = true;
      }

      this.checkForUnsyncedPayments();

      if (!client && this.clientInitialLoaded) {
        setTimeout(() => {
          this.noClientPanel?.focus();
        }, 1);
      }

      this.updatePaymentsDisabled();
    });
  }

  subscribeToPatient(): void {
    this.patient$ = this.store
      .pipe(select(getConversationPatient))
      .pipe(takeWhile(() => this.alive));

    this.patientSub$ = this.patient$.subscribe((patient) => {
      if (patient) {
        this.patient = patient;
        this.patientLoaded = true;
      }
    });
  }

  subscribeToStaffComments(): void {
    this.staffComments$ = this.store
      .pipe(select(getStaffCommentsReversed))
      .pipe(takeWhile(() => this.alive));
  }

  subscribeToConversationMessages(): void {
    this.messages$ = this.store
      .pipe(select(getMessagesReversed))
      .pipe(takeWhile(() => this.alive));

    this.messages$.subscribe((messages) => (this.messages = messages));
  }

  subscribeToConversationStatuses(): void {
    this.conversationStatuses$ = this.store
      .pipe(select(getConversationStatuses))
      .pipe(takeWhile(() => this.alive));
  }

  subscribeToClientLoading(): void {
    this.clientLoading$ = this.store
      .pipe(select(getClientLoading))
      .pipe(takeWhile(() => this.alive));
  }

  subscribeToPatientLoading(): void {
    this.patientLoading$ = this.store
      .pipe(select(isPatientLoading))
      .pipe(takeWhile(() => this.alive));
  }

  subscribeToAuthUser(): void {
    this.authUser$ = this.store
      .pipe(select(getUser))
      .pipe(takeWhile(() => this.alive));

    this.authUser$.subscribe((user) => {
      if (user) {
        this.authUser = user;
        this.store.dispatch(GetPracticeStaff());
      }
    });
  }

  subscribeToClientShared(): void {
    this.clientShared$ = this.store
      .pipe(select(isClientShared))
      .pipe(takeWhile(() => this.alive));
  }

  subscribeToPatientShared(): void {
    this.patientShared$ = this.store
      .pipe(select(isPatientShared))
      .pipe(takeWhile(() => this.alive));
  }

  subscribeToAvailablePatients(): void {
    this.availablePatients$ = this.store
      .pipe(select(getPatients))
      .pipe(takeWhile(() => this.alive));
  }

  subscribeToPatientHistoryLoading(): void {
    this.patientHistoryLoading$ = this.store
      .pipe(select(isPatientHistoryLoading))
      .pipe(takeWhile(() => this.alive));
  }

  subscribeToPatientHistory(): void {
    this.patientHistory$ = this.store
      .pipe(select(getPatientHistory))
      .pipe(takeWhile(() => this.alive));
  }

  subscribeToExternalConversationUser(): void {
    this.externalConversationUser$ = this.store
      .pipe(select(getExternalConversationUser))
      .pipe(takeWhile(() => this.alive));
  }

  checkForUnsyncedPayments(): void {
    if (this.paymentSyncingFeatureEnabled) {
      if (
        this.conversation &&
        this.conversation.client &&
        this.conversation.client.payments
      ) {
        this.paymentsFailedToSync = this.conversation.client.payments.filter(
          (payment) => {
            if (this.conversation) {
              return (
                payment.conversationId == this.conversation.id &&
                payment.paidAt &&
                payment.paidAt < subMinutes(new Date(), 1) &&
                !payment.syncedToPmsAt
              );
            }

            return false;
          },
        ).length;
      }
    }
  }

  handleStatusChange(status: ConversationStatus): void {
    if (this.conversationId) {
      this.store.dispatch(
        SetConversationStatus({ conversationId: this.conversationId, status }),
      );
    }
  }

  handleOwnerChange(owner: User): void {
    if (this.conversationId) {
      this.store.dispatch(
        SetConversationOwner({ conversationId: this.conversationId, owner }),
      );
    }
  }

  handleAssigneeChange(assignee: User | Group): void {
    if (this.conversationId) {
      this.store.dispatch(
        SetConversationAssignee({
          conversationId: this.conversationId,
          assignee,
        }),
      );
    }
  }

  handleSummaryChange(summary: string): void {
    if (this.conversationId) {
      this.store.dispatch(
        UpdateConversationSummary({
          conversationId: this.conversationId,
          summary,
        }),
      );
    }
  }

  handleClientChanged(): void {
    if (this.conversationId) {
      this.changingClient = true;
      this.store.dispatch(
        RemoveClientFromConversation({ conversationId: this.conversationId }),
      );
      this.store.dispatch(
        RemovePatientFromConversation({ conversationId: this.conversationId }),
      );
      this.store.dispatch(ClearPatients());
    }
  }

  handleShareClientToPms(client: Client): void {
    if (
      this.currentPractice?.pms === PMS.PROVET ||
      this.currentPractice?.pms === PMS.ASCEND ||
      this.currentPractice?.pms === PMS.EZYVET
    ) {
      if (client.url) {
        window.open(client.url, "_blank")?.focus();
      }
      return;
    }

    this.shareClientLoading = true;
    this.store.dispatch(ShareClientToPms({ client }));
  }

  handlePatientSelected(patient: Patient): void {
    this.store.dispatch(SetConversationPatient({ patient }));
  }

  handlePatientChanged(): void {
    if (this.conversationId) {
      this.store.dispatch(
        RemovePatientFromConversation({ conversationId: this.conversationId }),
      );
    }
  }

  handleSharePatientToPms(patient: Patient): void {
    if (
      this.currentPractice?.pms === PMS.PROVET ||
      this.currentPractice?.pms === PMS.ASCEND ||
      this.currentPractice?.pms === PMS.EZYVET
    ) {
      if (patient.url) {
        window.open(patient.url, "_blank")?.focus();
      }
      return;
    }

    this.sharePatientLoading = true;
    this.store.dispatch(SharePatientToPms({ patient }));
  }

  handleCommentSent(message: Delta): void {
    if (this.conversationId) {
      this.store.dispatch(
        AddComment({
          conversationId: this.conversationId,
          message: JSON.stringify(message),
        }),
      );
    }
  }

  handleClientSet(client: Client): void {
    this.store.dispatch(SetConversationClient({ client }));
  }

  handlePatientHistoryOpened(patientId: string): void {
    this.store.dispatch(GetPatientHistory({ patientId, skip: 0, limit: 10 }));
  }

  switchTab(tabName: ConversationPageTab): void {
    if (this.device == "desktop") {
      return;
    }

    this.activeTab = tabName;
  }

  togglePaymentRequested(open: boolean): void {
    if (open) {
      this.store.dispatch(OpenPaymentRequest());
    } else {
      this.store.dispatch(ClosePaymentRequest());
    }
  }

  paymentRequested(paymentRequest: PaymentRequest): void {
    let patientId;
    if (this.client && this.conversationId) {
      if (this.patient && this.patient.id) {
        patientId = this.patient.id;
      }

      this.store.dispatch(
        SubmitPaymentRequest({
          request: paymentRequest,
          conversationId: this.conversationId,
          clientId: this.client.id,
          patientId,
          siteId: paymentRequest.siteId,
        }),
      );

      this.activeTab = ConversationPageTab.CHAT;
    }
  }

  subscribeToPracticeStaff(): void {
    this.practiceUsers$ = this.store
      .pipe(select(getPracticeStaff))
      .pipe(takeWhile(() => this.alive));
  }

  subscribeToIsReloadingClient(): void {
    this.isReloadingClient$ = this.store
      .pipe(select(isReloadingClient))
      .pipe(takeWhile(() => this.alive));
  }

  subscribeToNewPayment(): void {
    this.paymentRequestOpen$ = this.store
      .pipe(select(isPaymentRequestOpen))
      .pipe(takeWhile(() => this.alive));
    this.paymentRequestLoading$ = this.store
      .pipe(select(getPaymentRequestLoading))
      .pipe(takeWhile(() => this.alive));
    this.newPaymentFailed$ = this.store
      .pipe(select(getSubmitPaymentRequestFailed))
      .pipe(takeWhile(() => this.alive));
  }

  updateChatIsDisabled(): void {
    this.chatIsDisabled =
      !!this.conversation?.resolvedAt || this.conversation?.users?.length === 0;
  }

  updatePaymentsDisabled(): void {
    this.paymentsDisabled =
      !!this.conversation?.resolvedAt ||
      this.conversation?.users?.length === 0 ||
      !this.client;
  }

  updateChatIsAwaitingResponse(): void {
    this.chatIsAwaitingResponse = !!(
      this.conversation &&
      (this.conversation.channel === "WhatsApp" ||
        this.conversation.channel === "WhatsApp360" ||
        this.conversation.channel === "WhatsApp360Cloud") &&
      (!this.conversation.timeSinceLastClientResponse ||
        this.conversation.timeSinceLastClientResponse >= 86400)
    );
  }

  markReadIfFeatureEnabled(): void {
    if (this.currentPractice && this.conversation && !this.markedAsReadOnOpen) {
      if (
        practiceHasFeature(
          this.currentPractice,
          PracticeFeature.MARK_CONVERSATION_READ_ON_OPEN,
        )
      ) {
        this.markedAsReadOnOpen = true;
        this.store.dispatch(
          MarkConversationsAsRead({ conversations: [this.conversation] }),
        );
      }
    }
  }

  updateFeaturesEnabled(): void {
    this.paymentsFeatureEnabled = false;
    this.productRequestFeatureEnabled = false;
    this.paymentSyncingFeatureEnabled = false;

    if (
      practiceHasFeature(this.currentPractice, PracticeFeature.PAYMENTS) &&
      practiceHasFeature(
        this.currentPractice,
        PracticeFeature.SHOW_PAYMENT_REQUEST_BUTTON,
      )
    ) {
      this.paymentsFeatureEnabled = true;
    }

    if (
      practiceHasFeature(this.currentPractice, PracticeFeature.PAYMENT_SYNCING)
    ) {
      this.paymentSyncingFeatureEnabled = true;
    }

    if (
      practiceHasFeature(this.currentPractice, PracticeFeature.PRODUCT_REQUESTS)
    ) {
      this.productRequestFeatureEnabled = true;
    }
  }

  handleStepToConversation(): void {
    if (
      this.device === "tablet" ||
      this.device === "tablet-small" ||
      this.device === "mobile"
    ) {
      this.activeTab = ConversationPageTab.TICKET;
    }
  }

  handleStepToChat(): void {
    this.activeTab = ConversationPageTab.CHAT;
  }

  handleStepToClient(): void {
    if (this.device === "mobile") {
      this.activeTab = ConversationPageTab.CLIENT;
    } else {
      this.activeTab = ConversationPageTab.CHAT;
    }
  }

  handleStepToClientAndOpenActions(): void {
    if (this.device === "mobile") {
      this.activeTab = ConversationPageTab.CLIENT;
    } else {
      this.activeTab = ConversationPageTab.CHAT;
    }

    if (this.device === "tablet-small") {
      this.openActionsPopup();
    }
  }

  handleStepToTeam(): void {
    if (
      this.device === "tablet" ||
      this.device === "tablet-small" ||
      this.device === "mobile"
    ) {
      this.activeTab = ConversationPageTab.TEAM_CHAT;
    }
  }

  handleStepToCloseActionsPopup(): void {
    this.closeActionsPopup();
  }

  handleStepToOpenActionsPopup(): void {
    this.openActionsPopup();
  }

  closeActionsPopup(): void {
    this.showActionsPopup = false;
  }

  openActionsPopup(): void {
    this.showActionsPopup = true;
  }

  toggleActionsPopup(): void {
    this.showActionsPopup = !this.showActionsPopup;
  }

  handleClickOutsideActionsPopup(target: any): void {
    this.closeActionsPopup();
  }

  handleWatch(user: User): void {
    if (this.conversation) {
      this.store.dispatch(
        AddWatcher({ conversation: this.conversation, user }),
      );
    }
  }

  handleUnwatch(user: User): void {
    if (this.conversation) {
      this.store.dispatch(
        RemoveWatcher({ conversation: this.conversation, user }),
      );
    }
  }

  handleClientRefresh(): void {
    if (this.conversationId) {
      this.store.dispatch(
        RefreshConversationClient({ conversationId: this.conversationId }),
      );
    }
  }

  paymentHistoryOpened(): void {
    if (this.conversation && this.conversation.client) {
      this.store.dispatch(GetClient({ clientId: this.conversation.client.id }));
    }
  }

  handleMorePatients(): void {
    this.store.dispatch(IncrementPatientPage());
  }
}
