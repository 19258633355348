<div class="p-component">
    <mobile-header></mobile-header>
    <desktop-header></desktop-header>
    <div class="p-d-flex wrapper">
        <div class="menu">
          <navigation></navigation>
        </div>
        <div class="body" [class.preview-open]="showConversationPreview$ | ngrxPush">
            <div class="left">
                <router-outlet></router-outlet>
            </div>
            <div class="right">
                <ng-container *ngIf="showConversationPreview$ | ngrxPush">
                    <conversation-preview></conversation-preview>
                </ng-container>
            </div>
        </div>
    </div>
</div>
