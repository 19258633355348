<div class="btn-row">
    <ng-container *ngIf="paymentsFeatureEnabled">
        <div class="col">
            <payment-request
                [paymentRequestLoading]="paymentRequestLoading"
                [paymentRequestOpen]="paymentRequestOpen"
                [paymentRequestFailed]="paymentRequestFailed"
                [outstanding]="outstanding"
                [disabled]="paymentRequestDisabled"
                [channel]="channel"
                [client]="client"
                [patient]="patient"
                (paymentRequest)="handlePaymentRequest($event)"
                (togglePaymentRequest)="handleTogglePaymentRequest($event)"
                (stepToConversation)="handleStepToConversation()"
                (stepToCloseActionsPopup)="handleStepToCloseActionsPopup()"
            ></payment-request>
        </div>
    </ng-container>
    <ng-container *ngIf="productRequestFeatureEnabled">
      <div class="col">
        <p-button styleClass="p-button-secondary p-button-fullwidth p-button-smalltext" (click)="startProductRequest()">
          <img alt="logo" src="assets/images/icons/icon-medicine.svg" />
          <span class="p-ml-1">Product request</span>
        </p-button>
      </div>
    </ng-container>
</div>
