<ng-container *ngIf="notification">
  <div class="wrapper">
    <div class="notification" (mouseover)="handleMouseOver()" (mouseout)="handleMouseOut()">
      <div class="close" (click)="dismiss()">
        <i class="pi pi-times"></i>
      </div>
      <div class="bar" [ngStyle]="{'backgroundColor': notification.barColor}"></div>
      <main>
        <div class="message">{{ notification.message }}</div>
        <div class="descr">{{ notification.description }}</div>
        <ng-container *ngIf="practice && practice.id !== notification.practice.id">
          <div class="practice-name">
            {{ notification.practice.name }}
          </div>
        </ng-container>
        <ng-container *ngIf="notification.note">
          <quill-view [content]="notification.note" format="object" theme="snow"></quill-view>
        </ng-container>
        <ng-container *ngIf="notification.attachmentType == attachmentTypes.CONVERSATION">
          <div (click)="navigateAndDismiss()" class="link">
              <img src="/assets/images/icons/conversation-blue.svg" alt=""> View conversation
          </div>
        </ng-container>
        <ng-container *ngIf="notification.attachmentType == attachmentTypes.PRODUCT_REQUEST">
          <div (click)="navigateAndDismiss()" class="link">
            <img src="/assets/images/icons/icon-medicine-blue.svg" alt=""> View product request
          </div>
        </ng-container>
        <ng-container *ngIf="notification.attachmentType == attachmentTypes.PAYMENT">
          <div (click)="navigateAndDismiss()" class="link">
            <img src="/assets/images/icons/payments-blue.svg" alt=""> View payments
          </div>
        </ng-container>
        <ng-container *ngIf="notification.attachmentType == attachmentTypes.FORM">
          <div (click)="navigateAndDismiss()" class="link">
            View Form Submission
          </div>
        </ng-container>
      </main>
      <aside>
        <ng-container *ngIf="notification.actioner">
          <user-avatar [user]="notification.actioner" size="small"></user-avatar>
        </ng-container>
        <ng-container *ngIf="!notification.actioner">
          <img src="/assets/images/vhd-circle.svg" width="30">
        </ng-container>
      </aside>
    </div>
  </div>
</ng-container>
