import {Component, Input, OnInit} from '@angular/core';
import {MessageStatus} from '../../../enums/message-status';

@Component({
  selector: 'message-status',
  templateUrl: './message-status.component.html',
  styleUrls: ['./message-status.component.scss']
})
export class MessageStatusComponent implements OnInit {
  @Input() status?: MessageStatus;

  constructor() { }

  ngOnInit(): void {
  }

}
