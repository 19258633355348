import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Message } from "../../../models/Message";
import { Conversation } from "../../../models/Conversation";
import { BehaviorSubject, interval, Observable, Subscription } from "rxjs";
import { User } from "src/app/models/User";
import { addSeconds, format } from "date-fns";
import { select, Store } from "@ngrx/store";
import { takeWhile } from "rxjs/operators";
import { AppState } from "../../../state/reducers";
import {
  getAllPreviousConversationsLoaded,
  getExternalConversationUser,
  getIsBlockSenderOpen,
  getPreviousConversations,
  isUserClientMismatch,
} from "../../state/selectors";
import {
  BlockSenderClose,
  BlockSenderOpen,
  ClearPreviousConversations,
  ConvertConversationToSMS,
  GetClient,
  GetConversationSuccess,
  GetPreviousConversations,
  MarkConversationsAsRead,
  MarkConversationsAsUnread,
  SetConversationAsRead,
  SetPreviousConversationsLoaded,
  UpdateClinicResponseToNow,
  UpdateUser,
} from "../../state/actions";
// @ts-ignore
import MicRecorder from "vhd-mic-recorder-to-mp3";
import {
  AddMessage,
  AddTempMessage,
  DeleteMessage,
  DeleteMessageSuccess,
  SendAudio,
  SendFile,
  SendMedia,
  SendVideo,
  StartSendingAudio,
  StartSendingFile,
  UpdateMessage,
  VideoCompressionJobComplete,
} from "../../../messages/state/actions";
import { CampaignMessage } from "src/app/models/CampaignMessage";
import { isConversation } from "src/app/helpers/is-conversation";
import { ReadStatus } from "../../../enums/read-status";
import { Client } from "../../../models/Client";
import { EnvironmentService } from "../../../services/environment.service";
import { EnterFullScreen, ExitFullScreen } from "../../../state/actions";
import { isFullScreen } from "../../../state/selectors";
import { DialogService } from "primeng/dynamicdialog";
import { DynamicDialogRef } from "primeng/dynamicdialog/dynamicdialog-ref";
import { MediaSelectorComponent } from "../../../media/components/media-selector/media-selector.component";
import { Media } from "../../../models/Media";
import { Template } from "../../../models/Template";
import { MediaSendComponent } from "../media-send/media-send.component";
import { WebsocketService } from "../../websocket.service";
import { Practice } from "../../../models/Practice";
import { MessageType } from "../../../enums/message-type";
import { getUser } from "../../../auth/state/selectors";
import { GetPracticeTemplates } from "../../../practices/state/actions";
import { MessageStatus } from "../../../enums/message-status";
import { addSignature } from "../../../helpers/add-signature";
import { getCurrentPractice } from "../../../practices/state/selectors";
import {
  getVideoCompressionJobId,
  getVideoUploadPercentage,
  isSendingAudio,
  isSendingFile,
  isVideoProcessing,
  isVideoUploading,
} from "../../../messages/state/selectors";
import { TemplateMergeFieldDo } from "../../../interfaces/template-merge-field.do.interface";
import { ConversationAdapter } from "../../../adapters/conversation.adapter";
import { MessageAdapter } from "../../../adapters/message.adapter";
import { getStandardAndCampaignTemplate } from "../../../templates/state/selectors";
import {
  ChangeMediaFolder,
  GetMediaFoldersSuccess,
  GetMediaSuccess,
  SetMediaFilters,
  SetMediaPage,
} from "../../../media/state/actions";
import { TemplateSubmissionStatus } from "../../../enums/template-submission-status";
import { ViewerType } from "../../../enums/viewers-type";
import { Role } from "../../../enums/role";
import { CookieService } from "ngx-cookie-service";
import { DialogTemplateSelectorComponent } from "../../../ui/components/dialog-template-selector/dialog-template-selector.component";
import { OpenFormRequest } from "../../../forms/state/actions";
import { Contact } from "../../../models/Contact";
import { practiceHasFeature } from "../../../helpers/practice-has-feature";
import { PracticeFeature } from "../../../enums/practice-feature";
import { Channel } from "src/app/enums/channel";

enum SendingType {
  MEDIA = "Media",
  FILE = "File",
  VIDEO = "Video",
}

@Component({
  selector: "conversation-messages",
  templateUrl: "./conversation-messages.component.html",
  styleUrls: ["./conversation-messages.component.scss"],
  providers: [DialogService],
})
export class ConversationMessages implements OnInit, OnChanges, OnDestroy {
  @ViewChild("chatContainer") private chatContainer: ElementRef | undefined;
  @ViewChild("textAreaElement") textAreaElement: ElementRef | undefined;
  @ViewChild("captureVideoUpload") captureVideoUpload: ElementRef | undefined;
  @ViewChild("capturePhotoUpload") capturePhotoUpload: ElementRef | undefined;
  @ViewChild("resendAll") resendAll: ElementRef | undefined;
  @Input() conversation: Conversation | null = null;
  @Input() client: Client | null = null;
  @Input() conversationContact: Contact | null = null;
  @Input() messageInput = "";
  @Input() messages$?: Observable<Message[] | null>;
  @Input() disabled = false;
  @Input() awaitingResponse = false;
  @Input() paymentsFailedToSync = 0;
  @Input() showConversationActions = true;
  @Input() version = "single";
  @Input() withAudioTooltip = true;
  @Output() stepToClient = new EventEmitter();
  alive = true;
  messages: Message[] | null = null;
  currentPractice$?: Observable<Practice | null>;
  currentPractice?: Practice | null;
  authUser$?: Observable<User | null>;
  authUser: User | null = null;
  user?: User | null;
  loadedAttachmentsCount = 0;
  loading = false;
  editContactActive = false;
  handlingScroll = false;
  showBottomShim = false;
  showTopShim = true;
  contact: User = {
    id: "",
    firstName: "",
    lastName: "",
    fullName: "",
    email: "",
    phone: "",
  };
  attachmentsOpen = false;
  currentlyRecording = false;
  recordingLength = 0;
  recordingInterval: any;
  recorder: MicRecorder;
  previousConversations$?: Observable<Array<
    Conversation | CampaignMessage
  > | null>;
  previousConversationsSubscription$?: Subscription;
  allPreviousConversationsLoaded$?: Observable<boolean>;
  allPreviousConversationsLoaded = false;
  previousConversations?: Array<Conversation | CampaignMessage>;
  showScrollDownPrompt = false;
  maxMessageLength = 1450;
  remainingMessageLength = this.maxMessageLength;
  messageTooLong = false;
  messageInvalid = false;
  invalidReason = "Over send limit";
  hasTouch = false;
  showTemplateDialog = false;
  captureSupported = false;
  uploadPhotoLabel = "Photo";
  uploadVideoLabel = "Video (max 60 secs)";
  uploadPhotoIcon = "pi-camera";
  uploadVideoIcon = "pi-video";
  readStatus = ReadStatus;
  actionsOpen = false;
  messageInfoOpen = false;
  messageWindowCloses: Date = new Date();
  helpLink = "";
  blockOpen$?: Observable<boolean>;
  blockSenderOpen = false;
  convertToSmsOpen = false;
  messageCount = 0;
  newMessages = 0;
  fullScreen$: Observable<boolean>;
  selectTemplateDialog?: DynamicDialogRef;
  selectMediaDialog?: DynamicDialogRef;
  sendMediaDialog?: DynamicDialogRef;
  sendingType: SendingType = SendingType.MEDIA;
  fileToSend: File | null = null;
  mediaToSend: Media | null = null;
  sendingFile$?: Observable<boolean>;
  sendingFile = false;
  sendingAudio$?: Observable<boolean>;
  sendingAudio = false;
  videoUploading$?: Observable<boolean>;
  videoUploading = false;
  videoProcessing$?: Observable<boolean>;
  videoProcessing = false;
  videoUploadPercentage$?: Observable<number>;
  videoUploadPercentage = 0;
  videoCompressionJobId: null | string = null;
  socketMessages$?: Subscription;
  lastSeenUpdates$?: Subscription;
  socketUnsentMessages$?: Subscription;
  socketMessageUpdates$?: Subscription;
  socketMessageDeletes$?: Subscription;
  socketVideoCompressionJobComplete$?: Subscription;
  socketConversation$?: Subscription;
  templates: Template[] = [];
  userClientMismatch: boolean | null = null;
  viewers$: Observable<User[] | null> = new BehaviorSubject(null);
  viewers: User[] = [];
  topBarMessage = "";
  impersonator = "";
  templateText: string | null = null;
  isTemplateEdited = false;
  selectedTemplate: Template | null = null;
  templateMedia: Media | undefined = undefined;
  selectedTemplateMergeFields: TemplateMergeFieldDo[] | undefined = undefined;
  isFormFeatureEnabled = false;
  buttonLink?: string;
  channels = Channel;

  constructor(
    private store: Store<AppState>,
    private environmentService: EnvironmentService,
    public dialogService: DialogService,
    private websocketService: WebsocketService,
    private conversationAdapter: ConversationAdapter,
    private messageAdapter: MessageAdapter,
    private cookieService: CookieService,
  ) {
    this.recorder = new MicRecorder({
      bitRate: 128,
    });

    let listener: () => void;

    window.addEventListener(
      "touchstart",
      (listener = () => {
        this.hasTouch = true;
        window.removeEventListener("touchstart", listener);
      }),
    );

    this.fullScreen$ = this.store
      .pipe(select(isFullScreen))
      .pipe(takeWhile(() => this.alive));
  }

  ngOnInit(): void {
    this.getHelpLink();
    this.checkForImpersonator();
    this.subscribeToPreviousConversations();
    this.subscribeToBlockSenderOpen();
    this.checkCaptureSupport();
    this.subscribeToNewMessages();
    this.subscribeToCurrentPractice();
    this.subscribeToAuthUser();
    this.subscribeToIsSendingAudio();
    this.subscribeToUploadingVideo();
    this.subscribeToVideoProcessing();
    this.subscribeToUploadVideoPercentage();
    this.subscribeToVideoCompressionJobId();
    this.subscribeToTemplates();
    this.subscribeToUserClientMismatch();
    this.subscribeToExternalConversationUser();
    this.init();
  }

  ngOnDestroy(): void {
    this.store.dispatch(ClearPreviousConversations());
    this.previousConversationsSubscription$?.unsubscribe();
    this.socketMessages$?.unsubscribe();

    this.lastSeenUpdates$?.unsubscribe();
    this.socketUnsentMessages$?.unsubscribe();
    this.socketMessageUpdates$?.unsubscribe();
    this.socketMessageDeletes$?.unsubscribe();

    if (this.version === "list" && this.conversation && this.authUser) {
      this.websocketService.leaveConversation(
        this.conversation.id,
        this.authUser.id,
      );
    }

    this.socketConversation$?.unsubscribe();

    this.alive = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.user) {
      this.contact = {
        id: this.user.id,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        phone: this.user.phone,
        fullName: this.user.firstName + " " + this.user.lastName,
        email: this.user.email,
      };
    }

    if (this.conversation) {
      if (this.conversation.channel === "SMS") {
        this.maxMessageLength = 400;
        this.remainingMessageLength = 400;
      }
    }

    if (
      changes.conversation &&
      changes.conversation.currentValue &&
      changes.conversation.previousValue &&
      changes.conversation.currentValue.id !==
        changes.conversation.previousValue.id
    ) {
      if (changes.conversation.previousValue && this.authUser) {
        this.websocketService.leaveConversation(
          changes.conversation.previousValue.id,
          this.authUser.id,
        );
      }

      if (changes.conversation.currentValue && this.authUser) {
        let sendUser = true;
        if (
          this.impersonator ||
          this.currentPractice?.userRole === Role.SUPER_ADMIN
        ) {
          sendUser = false;
        }
        this.websocketService.joinConversation(
          changes.conversation.currentValue.id,
          this.authUser?.id,
          sendUser,
        );
      }
    }
  }

  init(): void {
    this.websocketService.onConnect$.subscribe((connected) => {
      if (connected && this.conversation && this.authUser) {
        let sendUser = true;
        if (
          this.impersonator ||
          this.currentPractice?.userRole === Role.SUPER_ADMIN
        ) {
          sendUser = false;
        }
        this.websocketService.joinConversation(
          this.conversation.id,
          this.authUser?.id,
          sendUser,
        );
      }
    });

    this.socketConversation$ = this.websocketService
      .getConversationUpdates()
      .subscribe((data) => {
        const conversation = this.conversationAdapter.run(data);
        this.store.dispatch(GetConversationSuccess({ conversation }));

        if (conversation.client) {
          this.store.dispatch(GetClient({ clientId: conversation.client.id }));
        }
      });

    this.socketVideoCompressionJobComplete$ = this.websocketService
      .getVideoCompressionJobComplete()
      .subscribe((data) => {
        if (data === this.videoCompressionJobId) {
          this.store.dispatch(VideoCompressionJobComplete());
        }
      });

    this.lastSeenUpdates$ = interval(300000)
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => {
        if (this.conversation && this.authUser) {
          this.websocketService.updateLastSeen(
            this.conversation.id,
            this.authUser.id,
            ViewerType.CONVERSATION,
          );
        }
      });

    this.socketVideoCompressionJobComplete$ = this.websocketService
      .getVideoCompressionJobComplete()
      .subscribe((data) => {
        if (data === this.videoCompressionJobId) {
          this.store.dispatch(VideoCompressionJobComplete());
        }
      });

    this.socketMessages$ = this.websocketService
      .getMessages()
      .subscribe((data) => {
        if (data.conversationId.toString() === this.conversation?.id) {
          this.store.dispatch(
            AddMessage({ message: this.messageAdapter.run(data) }),
          );
        }
      });

    this.socketUnsentMessages$ = this.websocketService
      .getUnsentMessages()
      .subscribe((data) => {
        this.store.dispatch(
          UpdateMessage({ message: this.messageAdapter.run(data) }),
        );
      });

    this.socketMessageUpdates$ = this.websocketService
      .getMessageUpdates()
      .subscribe((data) => {
        this.store.dispatch(
          UpdateMessage({ message: this.messageAdapter.run(data) }),
        );
      });

    this.socketMessageDeletes$ = this.websocketService
      .getMessageDeletes()
      .subscribe((data) => {
        this.store.dispatch(
          DeleteMessageSuccess({ messageId: data.toString() }),
        );
      });
  }

  subscribeToCurrentPractice(): void {
    this.currentPractice$ = this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive));
    this.currentPractice$.subscribe((practice) => {
      this.currentPractice = practice;
      this.updateFeaturesEnabled();
      this.store.dispatch(GetPracticeTemplates());
    });
  }

  updateFeaturesEnabled(): void {
    if (!this.currentPractice$) {
      return;
    }

    if (practiceHasFeature(this.currentPractice, PracticeFeature.FORMS)) {
      this.isFormFeatureEnabled = true;
    }
  }

  subscribeToTemplates(): void {
    this.store
      .pipe(select(getStandardAndCampaignTemplate))
      .pipe(takeWhile(() => this.alive))
      .subscribe((templates) => {
        this.templates = templates;
      });
  }

  subscribeToUserClientMismatch(): void {
    this.store
      .pipe(select(isUserClientMismatch))
      .pipe(takeWhile(() => this.alive))
      .subscribe((mismatch) => {
        this.userClientMismatch = mismatch;
      });
  }

  subscribeToAuthUser(): void {
    this.authUser$ = this.store
      .pipe(select(getUser))
      .pipe(takeWhile(() => this.alive));

    this.authUser$.subscribe((user) => (this.authUser = user));
  }

  subscribeToExternalConversationUser(): void {
    this.store
      .pipe(select(getExternalConversationUser))
      .pipe(takeWhile(() => this.alive))
      .subscribe((user) => {
        this.user = user;
      });
  }

  subscribeToNewMessages(): void {
    this.messages$?.subscribe((messages) => {
      if (
        messages &&
        messages.length > this.messageCount &&
        this.showBottomShim
      ) {
        this.newMessages =
          messages.length - this.messageCount + this.newMessages;
      }
      this.messageCount = messages?.length || 0;
      this.messages = messages;

      setTimeout(() => {
        if (!this.showScrollDownPrompt) {
          if (this.chatContainer) {
            this.chatContainer.nativeElement.scrollTop = -1;
          }
          this.scrollToBottom();
        }

        this.scrollPromptEnabler();
      }, 50);
    });
  }

  subscribeToIsSendingFile(): void {
    this.sendingFile$ = this.store
      .pipe(select(isSendingFile))
      .pipe(takeWhile(() => this.alive));
    this.sendingFile$.subscribe((sending) => (this.sendingFile = sending));
  }

  subscribeToIsSendingAudio(): void {
    this.sendingAudio$ = this.store
      .pipe(select(isSendingAudio))
      .pipe(takeWhile(() => this.alive));
    this.sendingAudio$.subscribe((sending) => (this.sendingAudio = sending));
  }

  subscribeToUploadingVideo(): void {
    this.videoUploading$ = this.store
      .pipe(select(isVideoUploading))
      .pipe(takeWhile(() => this.alive));
    this.videoUploading$.subscribe(
      (uploading) => (this.videoUploading = uploading),
    );
  }

  subscribeToVideoProcessing(): void {
    this.videoProcessing$ = this.store
      .pipe(select(isVideoProcessing))
      .pipe(takeWhile(() => this.alive));
    this.videoProcessing$.subscribe(
      (processing) => (this.videoProcessing = processing),
    );
  }

  subscribeToUploadVideoPercentage(): void {
    this.videoUploadPercentage$ = this.store
      .pipe(select(getVideoUploadPercentage))
      .pipe(takeWhile(() => this.alive));
    this.videoUploadPercentage$.subscribe((percentage) => {
      this.videoUploadPercentage = percentage;
    });
  }

  subscribeToVideoCompressionJobId(): void {
    this.store
      .pipe(select(getVideoCompressionJobId))
      .pipe(takeWhile(() => this.alive))
      .subscribe((id: null | string) => {
        this.videoCompressionJobId = id;
      });
  }

  checkCaptureSupport(): void {
    const i = document.createElement("input") as any;
    i.setAttribute("capture", "true");

    if (i.capture) {
      this.captureSupported = true;
      this.uploadPhotoLabel = "Upload Photo";
      this.uploadVideoLabel = "Upload Video";
      this.uploadPhotoIcon = "pi-folder-open";
      this.uploadVideoIcon = "pi-folder-open";
    }
  }

  scrollToBottom(): void {
    this.newMessages = 0;
    if (this.chatContainer) {
      this.chatContainer.nativeElement.scrollTop = 1;
    }
  }

  send(): void {
    if (
      this.messageInput.trim() !== "" &&
      !this.disabled &&
      !this.messageInvalid
    ) {
      this.sendMessage(this.messageInput, MessageType.STANDARD);
      this.messageInput = "";

      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    }
  }

  sendConsentRequest(): void {
    this.reinitialise();
  }

  openEditContact(): void {
    this.editContactActive = true;
  }

  saveContact(): void {
    if (this.contact && this.contact.id) {
      this.handleSaveContact(this.contact);
      this.editContactActive = false;
    }
  }

  handleScroll(): void {
    if (!this.handlingScroll) {
      // Save performance on scroll
      this.handlingScroll = true;
      setTimeout(() => {
        this.shimEnabler();
        this.scrollPromptEnabler();
        this.handlingScroll = false;
      }, 50);
    }
  }

  checkForImpersonator(): void {
    const impersonator = this.cookieService.get("impersonate");
    if (impersonator) {
      this.impersonator = impersonator;
    }
  }

  shimEnabler(): void {
    if (this.chatContainer) {
      if (this.chatContainer.nativeElement.scrollTop >= 0) {
        this.showBottomShim = false;
      } else {
        this.showBottomShim = true;
      }

      if (
        this.chatContainer.nativeElement.scrollTop -
          this.chatContainer.nativeElement.offsetHeight <=
        1 - this.chatContainer.nativeElement.scrollHeight
      ) {
        this.showTopShim = false;
      } else {
        this.showTopShim = true;
      }
    }
  }

  scrollPromptEnabler(): void {
    if (this.chatContainer) {
      if (this.chatContainer.nativeElement.scrollTop > -50) {
        this.showScrollDownPrompt = false;
        this.newMessages = 0;
      } else {
        this.showScrollDownPrompt = true;
      }
    }
  }

  openMediaSendDialog(): void {
    this.sendMediaDialog = this.dialogService.open(MediaSendComponent, {
      header: "Send Media",
      modal: true,
      width: "600px",
      baseZIndex: 10000,
      data: {
        media: this.mediaToSend,
        file: this.fileToSend,
      },
    });

    this.attachmentsOpen = false;

    this.sendMediaDialog.onClose.subscribe((caption?: string) => {
      if (caption !== undefined) {
        switch (this.sendingType) {
          case SendingType.VIDEO:
            if (this.fileToSend) {
              this.sendVideoMessage({ file: this.fileToSend, caption });
            }
            break;
          case SendingType.FILE:
            if (this.fileToSend) {
              this.sendFileMessage({ file: this.fileToSend, caption });
            }
            break;
          case SendingType.MEDIA:
            if (this.mediaToSend) {
              this.sendMediaMessage({ media: this.mediaToSend, caption });
            }
            break;
        }
      }

      this.fileToSend = null;
      this.mediaToSend = null;
    });
  }

  uploadFile(event: any, fileUpload: any): void {
    this.sendingType = SendingType.FILE;
    this.fileToSend = event.files[0];
    fileUpload.clear();
    this.openMediaSendDialog();
  }

  uploadCapturePhoto(): void {
    if (this.capturePhotoUpload) {
      this.sendingType = SendingType.FILE;
      this.fileToSend = this.capturePhotoUpload.nativeElement.files[0];
      this.capturePhotoUpload.nativeElement.value = "";
      this.openMediaSendDialog();
    }
  }

  uploadVideo(event: any, fileUpload: any): void {
    this.sendingType = SendingType.VIDEO;
    this.fileToSend = event.files[0];
    fileUpload.clear();
    this.openMediaSendDialog();
  }

  uploadCaptureVideo(): void {
    if (this.captureVideoUpload) {
      this.sendingType = SendingType.VIDEO;
      this.fileToSend = this.captureVideoUpload.nativeElement.files[0];
      this.captureVideoUpload.nativeElement.value = "";
      this.openMediaSendDialog();
    }
  }

  trackMessage(index: number, message: Message): string | undefined {
    return message ? message.id : undefined;
  }

  trackPreviousConversation(
    index: number,
    conversation: Conversation | CampaignMessage,
  ): string | undefined {
    if (isConversation(conversation)) {
      return conversation ? conversation.id : undefined;
    } else {
      return conversation ? conversation.content : undefined;
    }
  }

  isConversationCheck(
    item: Conversation | CampaignMessage,
  ): item is Conversation {
    return isConversation(item);
  }

  handleStepToClient(): void {
    this.stepToClient.emit();
  }

  startRecording(): void {
    if (this.disabled) {
      return;
    }

    this.attachmentsOpen = false;

    this.recorder
      .start()
      .then(() => {
        this.currentlyRecording = true;
        this.recordingInterval = setInterval(() => {
          this.recordingLength++;
        }, 1000);
      })
      .catch((e: Error) => {
        console.error(e);
      });
  }

  cancelRecording(): void {
    this.recorder
      .stop()
      .getMp3()
      .then(() => {
        this.currentlyRecording = false;
        clearInterval(this.recordingInterval);
        this.recordingLength = 0;
      })
      .catch((e: Error) => {
        console.error(e);
      });
  }

  sendRecording(): void {
    this.recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]: [any, any]) => {
        const dtString = format(new Date(), "yyyyMMdd-HHmmss");
        const file = new File(buffer, dtString + "-recording.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });

        this.sendAudioMessage(file);

        this.currentlyRecording = false;
        clearInterval(this.recordingInterval);
        this.recordingLength = 0;
      })
      .catch((e: Error) => {
        console.error(e);
      });
  }

  subscribeToPreviousConversations(): void {
    this.previousConversations$ = this.store
      .pipe(select(getPreviousConversations))
      .pipe(takeWhile(() => this.alive));

    let scrollPos: number;
    this.previousConversationsSubscription$ =
      this.previousConversations$.subscribe((previousConversations) => {
        if (previousConversations) {
          if (this.chatContainer) {
            scrollPos = this.chatContainer.nativeElement.scrollTop;
          }

          this.previousConversations = previousConversations;

          if (this.chatContainer) {
            this.chatContainer.nativeElement.scrollTop = scrollPos + 1;
          }

          if (
            this.conversation &&
            this.previousConversations.length ===
              this.conversation.previousConversationCount
          ) {
            this.store.dispatch(
              SetPreviousConversationsLoaded({ loaded: true }),
            );
          }
        }
      });

    this.allPreviousConversationsLoaded$ = this.store
      .pipe(select(getAllPreviousConversationsLoaded))
      .pipe(takeWhile(() => this.alive));

    this.allPreviousConversationsLoaded$.subscribe(
      (open) => (this.allPreviousConversationsLoaded = open),
    );
  }

  loadPreviousConversations(): void {
    if (this.conversation) {
      const toSkip = this.previousConversations
        ? this.previousConversations.length
        : 0;
      this.store.dispatch(
        GetPreviousConversations({
          conversationId: this.conversation.id,
          skip: toSkip,
        }),
      );
    }
  }

  textareaResize($event: Event): void {
    if (
      this.textAreaElement &&
      this.textAreaElement.nativeElement.offsetHeight > 40
    ) {
      // Grim hack to stop primeng input from showing scrollbar when auto resizing
      this.textAreaElement.nativeElement.style.height =
        (this.textAreaElement.nativeElement.offsetHeight + 6).toString() + "px";
    }
  }

  onMessageInputChange(value: string): void {
    this.messageInput = value;

    this.remainingMessageLength = this.maxMessageLength - value.length;

    if (this.remainingMessageLength < 0) {
      this.messageTooLong = true;
      this.messageInvalid = true;
    } else {
      this.messageTooLong = false;
      this.messageInvalid = false;
    }
  }

  deleteMessage($event: string): void {
    this.store.dispatch(DeleteMessage({ messageId: $event }));
  }

  handleCaptureVideoClick(): void {
    if (!this.disabled && this.captureVideoUpload) {
      this.captureVideoUpload.nativeElement.click();
    }
  }

  handleCapturePhotoClick(): void {
    if (!this.disabled && this.capturePhotoUpload) {
      this.capturePhotoUpload.nativeElement.click();
    }
  }

  markRead(): void {
    if (this.conversation) {
      this.store.dispatch(
        MarkConversationsAsRead({ conversations: [this.conversation] }),
      );
    }
  }

  markUnread(): void {
    if (this.conversation) {
      this.store.dispatch(
        MarkConversationsAsUnread({ conversations: [this.conversation] }),
      );
    }
  }

  closeActionsOpen(): void {
    this.actionsOpen = false;
  }

  toggleActionsOpen(): void {
    if (this.disabled) {
      this.actionsOpen = false;
      return;
    }

    this.actionsOpen = !this.actionsOpen;
  }

  enterFullScreen(): void {
    this.store.dispatch(EnterFullScreen());
  }

  exitFullScreen(): void {
    this.store.dispatch(ExitFullScreen());
  }

  openMessageInfo(): void {
    this.messageInfoOpen = true;
  }

  closeMessageInfo(): void {
    this.messageInfoOpen = false;
  }

  subscribeToBlockSenderOpen(): void {
    this.blockOpen$ = this.store.pipe(select(getIsBlockSenderOpen));
    this.blockOpen$.subscribe((isOpen) => {
      this.blockSenderOpen = isOpen;
    });
  }

  closeBlockSender(): void {
    this.store.dispatch(BlockSenderClose());
  }

  openBlockSender(): void {
    this.store.dispatch(BlockSenderOpen());
  }

  getWindowClosesAt(): Date {
    if (
      this.conversation &&
      this.conversation.timeSinceLastClientResponse &&
      this.conversation.timeSinceLastClientResponse < 86400
    ) {
      const secondsRemaining =
        86400 - this.conversation.timeSinceLastClientResponse;
      const date = addSeconds(new Date(), secondsRemaining);

      return date;
    }

    return new Date();
  }

  handleTemplateSelected(event: {
    text: string;
    mergeFields: { placeholderId: string; content: string }[];
    template: Template;
    media?: Media;
    previewType?: string;
    buttonLink?: string;
  }): void {
    if (event.mergeFields) {
      this.selectedTemplateMergeFields = event.mergeFields;
    }

    if (event.media) {
      this.templateMedia = event.media;
    }

    this.buttonLink = event.buttonLink;

    if (
      event.template &&
      event.template.status === TemplateSubmissionStatus.Approved
    ) {
      this.selectedTemplate = event.template;
      this.sendMessage(
        event.text,
        MessageType.STANDARD,
        this.selectedTemplate,
        this.selectedTemplateMergeFields,
      );
    } else {
      this.onMessageInputChange(event.text);
    }
  }

  getHelpLink(): void {
    this.helpLink = `${this.environmentService.get(
      "helpUrl",
    )}/learn/section/digital-practice`;
  }

  openConvertToSms(): void {
    this.convertToSmsOpen = true;
  }

  closeConvertToSms(): void {
    this.convertToSmsOpen = false;
  }

  convertToSms(): void {
    this.closeConvertToSms();
    const resendAll = this.resendAll?.nativeElement.checked;
    if (this.conversation) {
      this.store.dispatch(
        ConvertConversationToSMS({
          conversation: this.conversation,
          resendAll,
        }),
      );
    }
  }

  selectMedia(): void {
    this.selectMediaDialog = this.dialogService.open(MediaSelectorComponent, {
      header: "Select Media",
      modal: true,
      width: "1000px",
      height: "80vh",
      baseZIndex: 10000,
      data: {},
    });

    this.selectMediaDialog.onClose.subscribe((media: Media) => {
      if (media) {
        this.sendingType = SendingType.MEDIA;
        this.mediaToSend = media;
        this.openMediaSendDialog();
      }

      this.store.dispatch(SetMediaPage({ page: 1 }));
      this.store.dispatch(SetMediaFilters({ filters: { types: [] } }));
      this.store.dispatch(ChangeMediaFolder({ folder: null, direction: "up" }));
      this.store.dispatch(GetMediaSuccess({ media: [], total: 0 }));
      this.store.dispatch(GetMediaFoldersSuccess({ folders: [] }));
    });
  }

  selectTemplate(): void {
    this.selectTemplateDialog = this.dialogService.open(
      DialogTemplateSelectorComponent,
      {
        header: "Select a template",
        modal: true,
        width: "1000px",
        baseZIndex: 10000,
        data: {
          templates: this.templates,
          client: this.client,
          patient: this.conversation?.patient,
          buttonClass: "p-button-success",
          includeMediaFilter: true,
        },
      },
    );

    this.selectTemplateDialog.onClose.subscribe((result) => {
      if (result) {
        this.handleTemplateSelected(result);
      }
    });
  }

  sendMessage(
    msg: string,
    type: MessageType,
    selectedTemplate?: Template,
    selectedTemplateMergeFields?: TemplateMergeFieldDo[],
  ): void {
    if (this.conversation) {
      this.doSendMessage(
        msg,
        type,
        selectedTemplate,
        selectedTemplateMergeFields,
      );
    }
  }

  doSendMessage(
    msg: string,
    type: MessageType,
    selectedTemplate?: Template,
    selectedTemplateMergeFields?: TemplateMergeFieldDo[],
  ): void {
    if (this.conversation) {
      this.store.dispatch(
        SetConversationAsRead({ conversationId: this.conversation.id }),
      );
      this.websocketService.sendMessage(
        this.conversation.id,
        msg,
        type,
        selectedTemplate,
        this.templateMedia,
        selectedTemplateMergeFields,
        this.buttonLink,
      );

      this.templateMedia = undefined;

      const tempMessage: Message = {
        id: "0",
        content: selectedTemplate
          ? msg
          : addSignature(msg, this.authUser, this.currentPractice),
        status:
          this.conversation.queueMode &&
          selectedTemplate?.status !== TemplateSubmissionStatus.Approved
            ? MessageStatus.queued
            : MessageStatus.accepted,
        outbound: true,
        createdAt: new Date(),
        author: this.authUser ?? undefined,
        type: MessageType.STANDARD,
      };
      this.store.dispatch(AddTempMessage({ message: tempMessage }));

      this.selectedTemplate = null;
    }
  }

  reinitialise(): void {
    if (this.conversation) {
      if (!this.conversation.timeSinceLastClinicResponse) {
        this.initialise();
      } else {
        this.store.dispatch(
          SetConversationAsRead({ conversationId: this.conversation.id }),
        );
        this.websocketService.sendReinitialiseMessage(this.conversation.id);
        this.store.dispatch(UpdateClinicResponseToNow());
      }
    }
  }

  initialise(): void {
    if (this.currentPractice && this.conversation) {
      this.store.dispatch(
        SetConversationAsRead({ conversationId: this.conversation.id }),
      );
      this.websocketService.sendInitialiseMessage(this.conversation.id);
      this.store.dispatch(UpdateClinicResponseToNow());
    }
  }

  sendFileMessage(event: { file: File; caption: string }): void {
    if (this.conversation) {
      this.store.dispatch(StartSendingFile());
      this.store.dispatch(
        SetConversationAsRead({ conversationId: this.conversation.id }),
      );
      this.store.dispatch(
        SendFile({
          conversationId: this.conversation.id,
          file: event.file,
          caption: event.caption,
        }),
      );
    }
  }

  sendVideoMessage(event: { file: File; caption: string }): void {
    if (this.conversation) {
      this.store.dispatch(StartSendingFile());
      this.store.dispatch(
        SetConversationAsRead({ conversationId: this.conversation.id }),
      );
      this.store.dispatch(
        SendVideo({
          conversationId: this.conversation.id,
          file: event.file,
          caption: event.caption,
        }),
      );
    }
  }

  sendAudioMessage(file: File): void {
    if (this.conversation) {
      this.store.dispatch(StartSendingAudio());
      this.store.dispatch(
        SetConversationAsRead({ conversationId: this.conversation.id }),
      );
      this.store.dispatch(
        SendAudio({ conversationId: this.conversation.id, file }),
      );
    }
  }

  sendMediaMessage(event: { media: Media; caption: string }): void {
    if (this.conversation) {
      this.store.dispatch(StartSendingFile());
      this.store.dispatch(
        SetConversationAsRead({ conversationId: this.conversation.id }),
      );
      this.store.dispatch(
        SendMedia({
          conversationId: this.conversation.id,
          media: event.media,
          caption: event.caption,
        }),
      );
    }
  }

  handleSaveContact(user: User): void {
    this.store.dispatch(UpdateUser({ user }));
  }

  startFormRequest(): void {
    this.store.dispatch(
      OpenFormRequest({
        client: this.client || undefined,
        contact: this.conversationContact || undefined,
        channel: this.conversation?.channel,
      }),
    );
  }
}
