import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import { Client } from '../../../models/Client';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getClientPaymentHistory } from '../../../conversation/state/selectors';
import { takeWhile } from 'rxjs/operators';
import { AppState } from '../../../state/reducers';
import { GetClientPaymentHistory } from '../../../conversation/state/actions';
import { FullPayment } from '../../../models/FullPayment';
import { Practice } from 'src/app/models/Practice';
import { getCurrentPractice } from 'src/app/practices/state/selectors';
import { Currency } from '../../../models/Currency';
import { getCurrencies } from '../../../state/selectors';
import {Payment} from "../../../models/Payment";
import { practiceHasFeature } from 'src/app/helpers/practice-has-feature';
import { PracticeFeature } from 'src/app/enums/practice-feature';
import { Router } from '@angular/router';
import { PaymentStatus } from '../../../enums/payment-status';

@Component({
  selector: 'payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit, OnDestroy, OnChanges {
  @Input() client?: Client;
  @Output() opened = new EventEmitter();
  alive = true;
  modalActive = false;
  now = new Date();
  practice$?: Observable<Practice | null>;
  practiceSub$?: Subscription;
  practice?: Practice | null;
  enabled = false;
  currencies: Currency[] = [];
  practiceCurrency?: Currency;
  unsyncedPayments = 0;
  paymentSyncEnabled = false;
  paymentStatus = PaymentStatus;

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    this.now = new Date();
    this.subscribeToCurrentPractice();
    this.subscribeToCurrencies();
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.practiceSub$?.unsubscribe();
  }

  ngOnChanges(): void {
    this.checkForUnsyncedPayments();
  }

  showModal(): void {
    if (this.client) {
      this.modalActive = true;
      this.opened.emit();
    }
  }

  checkForUnsyncedPayments(): void {
    this.unsyncedPayments = 0;

    if (this.paymentSyncEnabled) {
      if (this.client && this.client.payments) {
        this.unsyncedPayments = this.client.payments?.filter(payment => {
          return payment.paidAt && !payment.syncedToPmsAt
        }).length;
      }
    }
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store.pipe(select(getCurrentPractice)).pipe(
      takeWhile(() => this.alive)
    );

    this.practiceSub$ = this.practice$.subscribe((practice) => {
      this.practice = practice;
      this.updateEnabled();
      this.setPracticeCurrency();
      this.updatePaymentSyncEnabled();
      this.checkForUnsyncedPayments();
    });
  }

  subscribeToCurrencies(): void {
    this.store.pipe(select(getCurrencies)).pipe(
      takeWhile(() => this.alive)
    ).subscribe(currencies => {
      this.currencies = currencies;
      this.setPracticeCurrency();
    });
  }

  updatePaymentSyncEnabled(): void {
    if (this.practice) {
      if (practiceHasFeature(this.practice, PracticeFeature.PAYMENT_SYNCING)) {
        this.paymentSyncEnabled = true;
        return;
      }
    }
    this.paymentSyncEnabled = false;
  }

  setPracticeCurrency(): void {
    if (this.currencies.length && this.practice) {
      this.practiceCurrency = this.currencies.find((currency) => currency.currencyCode === this.practice?.currency);
    }
  }

  updateEnabled(): void {
    this.enabled = false;

    if (practiceHasFeature(this.practice, PracticeFeature.PAYMENTS)) {
      this.enabled = true;
    }
  }

  hideModal(): void {
    this.modalActive = false;
  }

  goToAllPayments(): void {
    if (this.client) {
      location.href = `search?s=${this.client.pmsId}&searchType=payments`;
    }
  }
}
