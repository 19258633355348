import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Conversation} from '../../../models/Conversation';
import {ReadStatus} from '../../../enums/read-status';
import {MarkConversationsAsRead, MarkConversationsAsUnread} from '../../state/actions';
import { select, Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import {Router} from "@angular/router";
import { interval, Observable, takeWhile } from 'rxjs';
import { isConversationPreviewEnabled } from '../../../state/selectors';
import { GetViewers } from '../../../viewers/state/actions';
import { ViewerType } from '../../../enums/viewers-type';

@Component({
  selector: 'conversation-list-item',
  templateUrl: './conversation-list-item.component.html',
  styleUrls: ['./conversation-list-item.component.scss']
})
export class ConversationListItem implements OnInit, OnDestroy {
  @Output() selectedToggled = new EventEmitter();
  @Output() clicked = new EventEmitter<Conversation>();
  @Input() conversation?: Conversation;
  @Input() selectable = false;
  @Input() selected = false;
  @Input() useLastResponseDate = false;
  @Input() showBorder = false;
  readStatus = ReadStatus;
  actionsOpen = false;
  refresh = new Date();
  alive = true;
  previewEnabled: boolean = true;

  constructor(private store: Store<AppState>, private router: Router) {
    this.store.pipe(select(isConversationPreviewEnabled)).pipe(takeWhile(() => this.alive)).subscribe(enabled => this.previewEnabled = enabled);
  }

  ngOnInit(): void {
    interval(30000).pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.refresh = new Date();
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  selectedChanged(): void {
    if (this.conversation) {
      this.selectedToggled.emit();
    }
  }

  toggleActions(): void {
    this.actionsOpen = !this.actionsOpen;
  }

  markRead(): void {
    this.actionsOpen = false;
    if (this.conversation) {
      this.store.dispatch(MarkConversationsAsRead({conversations: [this.conversation]}));
    }
  }

  markUnread(): void {
    this.actionsOpen = false;
    if (this.conversation) {
      this.store.dispatch(MarkConversationsAsUnread({conversations: [this.conversation]}));
    }
  }

  redirectToClient(clientId: string): void {
    this.router.navigateByUrl(`/clients/${clientId}`);
  }

  conversationClicked(e: any): void {
    if (e.target.id === 'client' && this.conversation && this.conversation.client) {
      this.redirectToClient(this.conversation.client.id)
    } else {
      if (this.previewEnabled) {
        if (this.conversation) {
          this.clicked.emit(this.conversation);
        }
        return;
      }
      this.navigateToConversation();
    }
  }

  navigateToConversation(): void {
    if (this.conversation) {
      this.router.navigateByUrl('/conversations/' + this.conversation.id);
    }
  }
}
