import {FormFieldPrefillOption} from '../enums/form-field-prefill-option.enum';
import {Client} from '../models/Client';
import {Patient} from '../models/Patient';

export function getFieldPrefillValue(prefillWith: FormFieldPrefillOption, client: Client, patient: Patient | null): string {
  switch (prefillWith) {
    case FormFieldPrefillOption.CLIENT_PMS_ID:
      return client.pmsId;

    case FormFieldPrefillOption.CLIENT_NAME:
      return client.fullName;

    case FormFieldPrefillOption.CLIENT_PHONE:
      return client.phone || '';

    case FormFieldPrefillOption.CLIENT_EMAIL:
      return client.email || '';

    case FormFieldPrefillOption.CLIENT_POSTCODE:
      return client.postcode || '';

    case FormFieldPrefillOption.PATIENT_PMS_ID:
      return patient?.pmsId || '';

    default:
      return '';
  }
}
