<ng-container *ngIf="practices.length > 1">
    <div class="search">
        <img src="assets/images/icons/search.svg" class="search-icon">
        <input pInputText type="text" placeholder="Search for a practice..." class="input-search"
            [style.width]="inputWidth" (keyup)="setFilteredPractices()" type="text" [(ngModel)]="practiceFilterValue">
        <ng-container *ngIf="practiceFilterValue.length > 0">
            <img src="assets/images/icons/cross.svg" class="cross-icon" (click)="clear()">
        </ng-container>
    </div>
    <div class="heading">
        Change Practice{{" "}}
        <span>
            {{ getFilteredPracticesText() }}
        </span>
    </div>
    <div class="wrap">
        <ng-container *ngFor="let practice of filteredPractices">
            <ng-container *ngIf="practice.id !== currentPractice?.id">
                <ng-container *ngIf="practice">
                    <div class="practice" (click)="handlePracticeChange(practice)">{{ practice.name }}</div>
                </ng-container>
                <ng-container *ngIf="!practice">
                    No practices found, please search again
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</ng-container>