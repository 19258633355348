import { createAction, props } from '@ngrx/store';
import { User } from '../../models/User';


export const CompleteTour = createAction(
  '[User] Complete tour',
  props<{user: User}>()
);

export const CompleteTourSuccess = createAction(
  '[User] Complete tour success',
  props<{user: User}>()
);
