<ng-container *ngIf="client">
    <div class="client-row">
        <div class="details-col">
            <div class="name">{{ client.title }} {{ client.firstName }} {{ client.lastName }} ({{ client.pmsId }})</div>
            <div class="address">
                {{ client.address1 ? client.address1 + ',' : '' }}
                {{ client.address2 ? client.address2 + ',' : '' }}
                {{ client.address3 ? client.address3 + ',' : '' }}
                {{ client.postcode ? client.postcode + ',' : '' }}
                {{ client.state }}
            </div>
            <div class="phone">{{ client.phone }}</div>
        </div>
        <div class="btn-col">
            <button pButton label="Select" class="p-button-sm p-button-ghost" (click)="selectClient()"></button>
        </div>
    </div>
</ng-container>
